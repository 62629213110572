import React, { useReducer, useEffect } from "react";
import fire from "../../services/fire";
import { useHistory } from "react-router-dom";
import Header from "../common/header";
import LoadingSpinner from "../common/loadingSpinner";
import AssemblyStartPage from "./AssemblyStartPage";
import AssemblyResources from "./AssemblyResources";
import MovementOne from "./MovementOne";
import MovementTwo from "./MovementTwo";
import MovementThree from "./MovementThree";
import {
  archAssemblyReducer,
  ArchAssemblyContext,
  INITIAL_STATE,
} from "./archAssemblyReducer";
import "./arch-assembly.css";
import GroupResponses from "./groupResponses";
import GroupThemeResponses from "./GroupThemeResponses";
import GroupResponseDetail from "./GroupResponseDetail";
import MoveTwoResponseDetail from "./MoveTwoResponseDetail";
import MoveTwoResponse from "./MoveTwoResponse";



const ArchAssemblyHome = ({ user }) => {
  const [assemblyState, assemblyStateDispatch] = useReducer(archAssemblyReducer, INITIAL_STATE);
  const history = useHistory();
  const { page, isLoading } = assemblyState;

  // Redundant Code
  const fetchAssemblyThemes = async () => {
    try {
      const themeSnapshot = await fire.firestore().collection("archassemblythemes").get();
      const themes = themeSnapshot.docs.map(doc => doc.data());
      return themes.sort((a, b) => a.themeNum - b.themeNum);
    } catch (error) {
      console.error("Error fetching themes:", error);
      return [];
    }
  };

  // fetch move one reflections
  const fetchGroupReflections = async () => {
    return new Promise((res, rej) => {
      const reflections = [];
      fire
        .firestore()
        .collection("archassembly2025")
        .doc("default")
        .collection("AAmovementOne")
        .get()
        .then((snapshot) => {
          if (!snapshot.empty) {
            snapshot.docs.forEach((doc) => {
              reflections.push(doc.data());
            });
          }
          res(reflections);
        })
        .catch((error) => rej(error));
    });
  };

  // submit move one reflections
  const submitMovementOneReflection = async (submissionData, callback) => {
    try {
      const docRef = fire.firestore()
        .collection("archassembly2025")
        .doc("default")
        .collection("AAmovementOne")
        .doc();
      const newResponseObj = {
        ...submissionData,
        id: docRef.id,
        createdAt: new Date(),
      };
  
      await docRef.set(newResponseObj);
  
      // Update the global state
      assemblyStateDispatch({
        type: "ADD_GROUP_SUBMISSION",
        payload: { submission: newResponseObj },
      });
  
      if (callback) callback();
    } catch (error) {
      console.error("Error submitting Movement One reflection:", error);
    }
  };

  // Move one edits
  const handleEditSubmit = async (responseId, updatedText) => {
    try {
      const updatedSubmission = {
        response: updatedText,
        updatedAt: new Date().toISOString(),
      };
  
      await fire.firestore()
        .collection("archassembly2025")
        .doc("default")
        .collection("AAmovementOne")
        .doc(responseId)
        .update(updatedSubmission);
  
      assemblyStateDispatch({
        type: "EDIT_GROUP_SUBMISSION",
        payload: {
          responseId,
          newText: updatedText,
          updatedAt: updatedSubmission.updatedAt, 
        },
      });
  
    } catch (error) {
      console.error("Error updating response:", error);
    }
  };

  const handleEditMoveTwoSubmit = async (responseId, updatedText) => {
    try {
      const updatedSubmission = {
        response: updatedText,
        updatedAt: new Date().toISOString(),
      };
  
      await fire.firestore()
        .collection("archassembly2025")
        .doc("default")
        .collection("AAmovementTwo")
        .doc(responseId)
        .update(updatedSubmission);
  
      assemblyStateDispatch({
        type: "EDIT_MOVE_TWO_RESPONSE",
        payload: {
          responseId,
          newText: updatedText,
          updatedAt: updatedSubmission.updatedAt,
        },
      });
  
    } catch (error) {
      console.error("Error updating Move 2 response:", error);
    }
  };
  

  // fetch move two reflections
  const fetchPersonalReflections = async () => {
    try {
      const snapshot = await fire.firestore()
        .collection("archassembly2025")
        .doc("default")
        .collection("AAmovementTwo")
        .get();
      if (snapshot.empty) return [];
  
      return snapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          submittedBy: data.submittedBy || "",  // Ensure submittedBy exists
          themeNum: data.themeNum || null,      // Ensure themeNum exists
        };
      });
    } catch (error) {
      console.error("Error fetching personal reflections:", error);
      return [];
    }
  };

  // submit move two reflections
  const submitMovementTwoReflection = async (reflectionData, callback) => {
    try {
      //console.log("Submitting Reflection:", reflectionData); 
      const docRef = fire.firestore()
        .collection("archassembly2025")
        .doc("default")
        .collection("AAmovementTwo")
        .doc();
      const newResponseObj = {
        ...reflectionData,
        id: docRef.id,
        createdAt: new Date(),
      };
  
      await docRef.set(newResponseObj);
  
      // ✅ Update Global State
      assemblyStateDispatch({
        type: "UPDATE_MOVEMENT_2",
        payload: { reflections: [...assemblyState.reflections, newResponseObj] },
      });
  
      if (callback) callback();
    } catch (error) {
      console.error("Error submitting Movement 2 reflection:", error);
    }
  };
  
  

  // fetch move 3 data
  const fetchSession3Responses = async () => {
    try {
      const snapshot = await fire.firestore()
        .collection("archassembly2025")
        .doc("default")
        .collection("AAmovementThree")
        .where("submittedBy", "==", user.userid) 
        .get();
  
      if (snapshot.empty) {
        console.warn("No session3 responses found, returning an empty array.");
        return []; 
      }
  
      const responses = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
  
  
      return responses; 
    } catch (error) {
      console.error("Error fetching session3 responses:", error);
      return []; 
    }
  };
  


  // submit move 3 data
  const submitMovementThree = async (session3Data, callback) => {
    try {
      const docRef = fire.firestore()
        .collection("archassembly2025")
        .doc("default")
        .collection("AAmovementThree")
        .doc();
      const newResponseObj = {
        ...session3Data,
        id: docRef.id,
        createdAt: new Date().toISOString(),
      };
  
      await docRef.set(newResponseObj);
  
      assemblyStateDispatch({
        type: "UPDATE_MOVEMENT_3",
        payload: { session3Responses: [...assemblyState.session3Responses, newResponseObj] },
      });
  
      if (callback) callback();
    } catch (error) {
      console.error("Error submitting Movement 3 response:", error);
    }
  };
  
  

  const fetchSettings = async () => {
    try {
      const settingSnapshot = await fire.firestore().collection("settings").doc("web").get();
      if (settingSnapshot.exists) {
        const settingObj = settingSnapshot.data();
        return {
          isMovementOneOpen: !!settingObj.archassembly?.isMovementOneOpen,
          isMovementTwoOpen: !!settingObj.archassembly?.isMovementTwoOpen,
          isMovementThreeOpen: !!settingObj.archassembly?.isMovementThreeOpen,
        };
      } else {
        return {
          isMovementOneOpen: false,
          isMovementTwoOpen: false,
          isMovementThreeOpen: false,
        };
      }
    } catch (error) {
      console.error("Error fetching settings:", error);
      return {
        isMovementOneOpen: false,
        isMovementTwoOpen: false,
        isMovementThreeOpen: false,
      };
    }
  };

  // Fetch all data concurrently once the component mounts:
  useEffect(() => {
    if (!user || !user.archassembly2025) {
      console.warn("user data missing");
      return;
    }

    const fetchData = async () => {
      const fetchTime = new Date();
      const [
        themes,
        groupReflections,
        personalReflections,
        session3Responses,
        assemblySettings,
      ] = await Promise.all([
        fetchAssemblyThemes(),
        fetchGroupReflections(),
        fetchPersonalReflections(),
        fetchSession3Responses(),
        fetchSettings(),
      ]);

      // Dispatch one action with the full payload.
      assemblyStateDispatch({
        type: "SET_DATA_READY",
        payload: {
          themes,
          groupSubmissions: groupReflections,
          reflections: personalReflections,
          session3Responses : session3Responses,
          settings: assemblySettings,
          delegateProfile: {
            name: user.fullname,
            parish: user.parish,
            dob: user.dob,
            email: user.email,
            mobile: user.mobile,
            userid: user.userid,
            ...user.archassembly2025,
          },
          lastUpdated: fetchTime,
          isLoading: false,
        },
      });
    };

    fetchData();
  }, [user, assemblyStateDispatch]);

  const goBack = () => {
    assemblyStateDispatch({
      type: "SET_PAGE",
      payload: { page: "HOME" },
    });
  };

  return (
    <ArchAssemblyContext.Provider
      value={{
        assemblyState,
        assemblyStateDispatch,
        submitMovementOneReflection,
        submitMovementTwoReflection,
        submitMovementThree,
        handleEditSubmit,
        handleEditMoveTwoSubmit
      }}
    >
      <Header smallpic={user.photoURL} />
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          {/* {page !== "HOME" && (
            // <div
            //   className="container mt-2 text-primary"
            //   style={{ cursor: "pointer" }}
            //   onClick={goBack}
            // >
            //   Return to previous page
            // </div>
          )} */}
          <div className="container">
            {page === "HOME" && <AssemblyStartPage />}
            {page === "ASSEMBLY_RESOURCES" && <AssemblyResources />}
            {page === "MOVEMENT_1" && <MovementOne />}
            {page === "MOVEMENT_2" && <MovementTwo />}
            {page === "MOVEMENT_3" && <MovementThree />}
            {page === "GROUP_RESPONSES" && <GroupResponses />}
            {page === "THEME_RESPONSES" && <GroupThemeResponses />}
            {page === "RESPONSE_DETAIL" && <GroupResponseDetail />}
            {page === "MOVE_TWO_RESPONSE" && <MoveTwoResponse />}
            {page === "RESPONSE_DETAIL_MOVE2" && <MoveTwoResponseDetail />}

          </div>
        </>
      )}
    </ArchAssemblyContext.Provider>
  );
};

export default ArchAssemblyHome;
