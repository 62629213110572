import { createContext } from "react";

  export const INITIAL_STATE = {
    delegateProfile: {},
    isLoading: true,
    page: "HOME",
    themes: [],
    lastUpdated: "",
    groupSubmissions: [], // Session 1
    reflections: [],      // Session 2 
    session3Responses: [], // Session 3
  };

  export const archAssemblyReducer = (state, action) => {
    const { type, payload } = action;
    switch (type) {
      case "SET_DATA_READY":
        // Merge new payload into state; missing fields won't override existing ones.
        return {
          ...state,
          ...payload,
          isLoading: false,
        };
      case "SET_PAGE":
        return {
          ...state,
          page: payload.page,
        };
      case "SET_THEME_AND_PAGE": // For moveOne
        return {
          ...state,
          themeNum: payload.themeNum, 
          page: payload.page,
        };        
      case "SET_RESPONSE_DETAIL": // for moveOne
        return {
          ...state,
          page: "RESPONSE_DETAIL",
          responseDetailId: action.payload.responseId, 
        };
        case "SET_MOVE_TWO_THEME_AND_PAGE":
          return {
            ...state,
            moveTwoThemeNum: payload.themeNum, // Store themeNum
            page: payload.page,
          };
        
      case "SET_SETTINGS":
        return {
          ...state,
          settings: {
            ...state.settings,
            ...payload.settings,
          },
        };
      case "UPDATE_MOVEMENT_2":
        return {
          ...state,
          reflections: payload.reflections, // Movement 2: Personal Reflections
        };
      case "UPDATE_MOVEMENT_3":
        return {
          ...state,
          session3Responses: payload.session3Responses,
        };
      case "UPDATE_ALL_RESPONSES":
        return {
          ...state,
          reflections: payload.reflections,
          groupSubmissions: payload.groupSubmissions,
          session3Responses: payload.session3Responses || state.session3Responses,
          lastUpdated: payload.lastUpdated,
        };
      case "ADD_GROUP_SUBMISSION":
        return {
          ...state,
          groupSubmissions: [...state.groupSubmissions, payload.submission],
        };
      case "EDIT_GROUP_SUBMISSION":
        return {
          ...state,
          groupSubmissions: state.groupSubmissions.map(submission =>
            submission.id === payload.responseId
              ? { ...submission, response: payload.newText, updatedAt: payload.updatedAt }
              : submission
          ),
        };
        case "EDIT_MOVE_TWO_RESPONSE":
          return {
            ...state,
            reflections: state.reflections.map(submission =>
              submission.id === payload.responseId
                ? { ...submission, response: payload.newText, updatedAt: payload.updatedAt }
                : submission
            ),
          };
        
        
      default:
        return state;
    }
  };

  export const ArchAssemblyContext = createContext({
    assemblyState: {},
    assemblyStateDispatch: () => {},
    refreshAllResponse: () => {},
    submitMovementOneReflection: () => {},
    submitMovementTwoReflection: () => {},
    submitMovementThree: () => {},
  });
