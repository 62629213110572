import React, { useContext } from "react";
import { ArchAssemblyContext } from "./archAssemblyReducer";
import themes from "./themes.json";

const GroupResponses = () => {
  const { assemblyState, assemblyStateDispatch } = useContext(ArchAssemblyContext);
  const { delegateProfile } = assemblyState;

  const selectedTheme = delegateProfile?.themeNum || null; // The group's assigned theme

  const handleThemeClick = (themeNum) => {
    assemblyStateDispatch({ 
      type: "SET_THEME_AND_PAGE", 
      payload: { themeNum, page: "THEME_RESPONSES" } 
    });
  };
  

  return (
    <div className="p-4 max-w-3xl mx-auto">
      <h2 className="text-xl font-bold mb-4">Group Reflection</h2>
      <p className="text-gray-600">Click on a theme to view responses from different groups.</p>

      {/* Theme List */}
      <div className="mt-4">
        {Object.entries(themes).map(([key, theme]) => {
          const themeNum = parseInt(key);
          const isGroupTheme = Number(themeNum) === Number(selectedTheme);

          return (
            <button
              key={key}
              className="btn btn-light border d-flex justify-content-between align-items-center w-100 p-3 mb-2"
              onClick={() => handleThemeClick(themeNum)}
              style={{
                fontSize: "clamp(14px, 4vw, 18px)", // Responsive font size
                textAlign: "left",
              }}
            >
              {/* Left-aligned text with extra padding */}
              <div className="text-start w-100 ps-2">{theme}</div>

              {/* Right-aligned elements: Badge + Arrow */}
              <div className="d-flex align-items-center gap-2">
                {isGroupTheme && (
                  <span
                    className="badge badge-success text-wrap"
                    style={{
                      whiteSpace: "pre-line", // Forces line break between "Group's" and "Theme"
                      textAlign: "center",
                      fontSize: "clamp(12px, 3vw, 14px)", // Responsive font size
                      padding: "6px 8px",
                    }}
                  >
                    Group's{"\n"}Theme
                  </span>
                )}
                <span style={{ color: "#E08338", fontSize: "clamp(16px, 5vw, 20px)" }}>&rarr;</span>
              </div>
            </button>
          );
        })}
      </div>


      {/* Back Button */}
      <div className="d-flex justify-content-center">
      <button
        className="btn btn-link mt-3"
        style={{ textDecoration: "underline", color: "black" }}
        onClick={() => assemblyStateDispatch({ type: "SET_PAGE", payload: { page: "MOVEMENT_1" } })}
      >
        Back
      </button>
      </div>
    </div>
  );
};

export default GroupResponses;
