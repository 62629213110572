import React, { useContext, useState } from "react";
import { ArchAssemblyContext } from "./archAssemblyReducer";
import fire from "../../services/fire"; // Ensure this imports Firebase functions
import AppGlobalContext from "../../AppGlobalContext";

const AssemblyResources = () => {
  const { assemblyState, assemblyStateDispatch } =
    useContext(ArchAssemblyContext);
  const { user } = useContext(AppGlobalContext);
  const { delegateProfile, settings } = assemblyState;
  const [isDPLoading, setIsDPLoading] = useState(false);
  const [isWorkbookLoading, setIsWorkbookLoading] = useState(false);

  // Direct URLs from Firebase Storage
  const workbookURL =
    "https://drive.google.com/file/d/1Z1UebEpmp7AF7ymAd0tM_Q_Sk2h7rGjq/view?usp=sharing";

  const downloadWorkbook = () => {
    setIsWorkbookLoading(true);
    window.open(workbookURL, "_blank");
    setTimeout(() => setIsWorkbookLoading(false), 3000);
  };

  const downloadDelegateProfiles = async () => {
    if (!user?.archassembly2025?.isDelegate) {
      //console.error("Access denied: You do not have permission to download this file.");
      alert("Access denied: You do not have permission to download this file.");
      return;
    }
  
    setIsDPLoading(true);

    try {
      const fetchSignedFile = fire
        .functions("asia-east2")
        .httpsCallable("getSignedFile");

      const result = await fetchSignedFile({
        user: { userid: user.userid }, // Ensure user authentication
        fileKey:
          "archassembly2025/Delegate Profiles - Archdiocesan Assembly 2025.pdf", // 🔹 Updated path
      });

      if (result.data && result.data.file) {
        window.open(result.data.file, "_blank");
      // } else {
      //   console.error("Error: No file URL returned.");
      }
    } catch (error) {
      console.error("Error fetching signed file:", error);
    } finally {
      setTimeout(() => setIsDPLoading(false), 3000);
    }
  };

  const goBack = () => {
    assemblyStateDispatch({
      type: "SET_PAGE",
      payload: { page: "HOME" },
    });
  };

  const openPastoralPlan = () => {
    window.open("https://www.catholic.sg/apc-schema", "_blank"); // Opens Google in a new tab
  };

  return (
    <div className="d-flex flex-column align-items-center p-4">
      <h2 className="text-center">Read about the Assembly</h2>
      <p className="text-muted text-center">
        Before participating in the Assembly, we request that you download and
        read through the Workbook and Delegate Profiles.
      </p>

      <button
        className="btn my-2 w-75"
        style={{
          backgroundColor: "#E08338",
          color: "#fff",
          borderRadius: "10px",
        }}
        onClick={!isWorkbookLoading ? downloadWorkbook : undefined}
        disabled={isWorkbookLoading}
      >
        {isWorkbookLoading ? "Loading..." : "Download Workbook"}
      </button>

      <button
        className="btn my-2 w-75 text-center"
        style={{
          backgroundColor: "#E08338",
          color: "#fff",
          borderRadius: "10px",
          padding: "14px 20px",
          fontSize: "clamp(13px, 3.5vw, 16px)", // Dynamic font sizing
          lineHeight: "1.4",
          textAlign: "center",
          maxWidth: "100%",
          whiteSpace: "normal", // Ensures text wraps properly
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        onClick={!isDPLoading ? downloadDelegateProfiles : undefined}
        disabled={isDPLoading}
        >
        {isDPLoading ? (
          "Loading..."
        ) : (
          <>
            <span style={{ fontSize: "clamp(13px, 3.5vw, 16px)", fontWeight: "400" }}>
              Download Delegate Profiles
            </span>
            <span style={{ fontSize: "clamp(10px, 3vw, 12px)", fontWeight: "400" }}>
              (not to be shared outside of AA)
            </span>
          </>
        )}
        </button>



      {/* Button for opening the Schema of the Pastoral Plan */}
      <button
        className="btn btn-link mt-3"
        style={{ textDecoration: "underline", color: "black" }}
        onClick={openPastoralPlan}
      >
        Schema of the Pastoral Plan
      </button>

      <button className="btn btn-secondary mt-4 w-50" onClick={goBack}>
        Back
      </button>
    </div>
  );
};

export default AssemblyResources;
