import React from "react";
import { Carousel } from "react-bootstrap";
import CustomBanner from "../../common/customBanner";
import { logEvent } from "../../../services/log";
import { Link } from "react-router-dom";

const item = [
  // {
  //   id: 1,
  //   header: "We are hiring!",
  //   content: "Digital Church Office is hiring!",
  //   link: "/hiring",
  //   redirection: "internal",
  //   bannertype: "default",
  //   img: "https://firebasestorage.googleapis.com/v0/b/mycatholicsg-prod01.appspot.com/o/cdn%2Fbanner%2Fdco%2Fdco-hiring.png?alt=media&token=bb3f97c6-22be-42ff-8432-47bfdd839fe6",
  //   interval: 1200,
  //   orgCode: "DCO",
  //   logId: "doc_job",
  // },
  // {
  //   id: 2,
  //   header: "Advent to Christmas",
  //   content: "Reflection Booklet",
  //   link: "/one",
  //   redirection: "internal",
  //   bannertype: "default",
  //   img: "https://firebasestorage.googleapis.com/v0/b/mycatholicsg-prod01.appspot.com/o/cdn%2Fbanner%2Fone%2Fadvent-to-christmas.jpg?alt=media&token=380d9d98-bad9-4120-8f1e-b807b4a1f327",
  //   interval: 3000,
  //   orgCode: "ONE",
  //   logId: "one_adv",
  // },
  {
    id: 3,
    header: "Season of Lent",
    content: "Reflection Booklet",
    link: "/one",
    redirection: "internal",
    bannertype: "default",
    img: "https://firebasestorage.googleapis.com/v0/b/mycatholicsg-prod01.appspot.com/o/cdn%2Fbanner%2Fone%2FLent%20Easter%202025%20Banner%20.png?alt=media&token=d249d96d-73be-424e-bab9-e8961c505625",
    interval: 3000,
    orgCode: "ONE",
    logId: "one_lent_2025_banner",
  },
];

function BannerCarousel({ source = "/" }) {
  const handleClick = (logId, orgCode) => {
    sessionStorage.setItem("previousPage", window.location.pathname); // Store previous page
    logEvent(`${logId}`, { type: orgCode });
  };

  return (
    <Carousel indicators={false} controls={false}>
      {item?.map((i) => {
        const interval = i?.interval || 2000;
        const logId = `${i?.logId}_${source}`;
        return i?.bannertype === "default" ? (
          <Carousel.Item key={i.id} interval={interval}>
            <Link onClick={() => handleClick(logId, i?.orgCode)} to={i?.link}>
              <div className="d-block pointer">
                <img
                  className="bannerimagetop mb-3 "
                  // className="container-fluid px-0"
                  // style={{ height: "114px" }}
                  src={i?.img}
                  alt=""
                />
              </div>
            </Link>
          </Carousel.Item>
        ) : (
          <Carousel.Item key={i.id} interval={interval}>
            <div className="d-block w-100">
              <CustomBanner link={i?.link} code={source} />
            </div>
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
}

export default BannerCarousel;
