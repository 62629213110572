import fire from "../../../services/fire";
import arch from "../../../img/priest/arch.jpg";
import blessedsacrament from "../../../img/priest/blessedsacrament.jpg";
import cathedral from "../../../img/priest/cathedral.jpg";
import ctk from "../../../img/priest/ctk.jpg";
import divinemercy from "../../../img/priest/divinemercy.jpg";
import holycross from "../../../img/priest/holycross.jpg";
import holyfamily from "../../../img/priest/holyfamily.jpg";
import holyspirit from "../../../img/priest/holyspirit.jpg";
import holytrinity from "../../../img/priest/holytrinity.jpg";
import immaculateheartofmary from "../../../img/priest/immaculateheartofmary.jpg";
import ladyoflourdes from "../../../img/priest/ladyoflourdes.jpg";
import nativityofblessedvirgin from "../../../img/priest/nativityofblessedvirgin.jpg";
import perpertualsuccour from "../../../img/priest/perpertualsuccour.jpg";
import queenofpeace from "../../../img/priest/queenofpeace.jpg";
import starofthesea from "../../../img/priest/starofthesea.jpg";
import risenchrist from "../../../img/priest/risenchrist.jpg";
import sacredheart from "../../../img/priest/sacredheart.jpg";
import novena from "../../../img/priest/novena.jpg";
import stanne from "../../../img/priest/stanne.jpg";
import stanthony from "../../../img/priest/stanthony.jpg";
import stbernadette from "../../../img/priest/stbernadette.jpg";
import stfrancisassisi from "../../../img/priest/stfrancisassisi.jpg";
import stfrancisxavier from "../../../img/priest/stfrancisxavier.jpg";
import stignatius from "../../../img/priest/stignatius.jpg";
import stjosephbukittimah from "../../../img/priest/stjosephbukittimah.jpg";
import stjosephvictoria from "../../../img/priest/stjosephvictoria.jpg";
import stmaryofangels from "../../../img/priest/stmaryofangels.jpg";
import stmichael from "../../../img/priest/stmichael.jpg";
import ststephen from "../../../img/priest/ststephen.jpg";
import stteresa from "../../../img/priest/stteresa.jpg";
import stvincentdepaul from "../../../img/priest/stvincentdepaul.jpg";
import stspeterandpaul from "../../../img/priest/stspeterandpaul.jpg";
import transfiguration from "../../../img/priest/transfiguration.jpg";


export const priestimages = [
  { id: 0, temp: true, photo: arch },
  { id: 1, photo: blessedsacrament },
  { id: 2, temp: true, photo: cathedral },
  {id: 3,temp: true,photo: ctk },
  { id: 4, photo: divinemercy },
  { id: 5, photo: holycross },
  { id: 6, photo: holyfamily },
  { id: 7, temp: true, photo: holyspirit },
  { id: 8, temp: true, photo: holytrinity },
  { id: 9, photo: immaculateheartofmary },
  { id: 10, photo: ladyoflourdes },
  { id: 11,temp: true,photo: nativityofblessedvirgin },
  { id: 12,temp: true,photo: perpertualsuccour },
  { id: 13,temp: true, photo: queenofpeace },
  { id: 14,temp: true, photo: starofthesea },
  { id: 15, photo: risenchrist },
  { id: 16, photo: sacredheart },
  { id: 17, temp: true, photo: novena },
  { id: 18, temp: true, photo: stanne },
  { id: 19, temp: true, photo: stanthony },
  { id: 20, photo: stbernadette },
  { id: 21, photo: stfrancisassisi },
  { id: 22, photo: stfrancisxavier },
  { id: 23, photo: stignatius },
  { id: 24, photo: stjosephbukittimah },
  { id: 25,temp: true,photo: stjosephvictoria },
  { id: 26,temp: true, photo: stmaryofangels },
  { id: 27, temp: true, photo: stmichael },
  { id: 28, temp: true, photo: ststephen },
  { id: 29, temp: true, photo: stteresa },
  { id: 30, photo: stvincentdepaul },
  { id: 31, photo: stspeterandpaul },
  {id: 32, temp: true, photo: transfiguration },
  { id: 33, temp: true, photo: arch },
];

export function getPriestImages() {
  return priestimages;
}

export function getParishPriestImageById(id) {
  const [priest] = priestimages.filter((g) => g.id === parseInt(id));
  //override here, if none, use parish church photo
  let photo = priest.photo;
  //   if (priest.temp) {
  //     photo = "temp";
  //   }
  return photo;
}

export async function getPriests(sort = true) {
  const orgs = await fire
    .firestore()
    .collection(`person`)
    .where("status", "!=", "")
    .get();

  const results = [];
  orgs.forEach((entry) => {
    const data = entry.data();
    results.push({ ...data, id: data.id });
  });

  if (sort === true && results.length > 0) {
    results.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      } else if (a.name > b.name) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  return results;
}

export async function savePriest(data, empty) {
  const dataRef = fire.firestore().collection("person").doc();
  let obj;
  if (empty) {
    //this is a fresh data
    obj = { ...data, uid: dataRef.id };
    await dataRef.set(obj);
  } else {
    const lastUpdate = new Date().getTime();
    obj = { ...data, lastUpdate, uid: dataRef.id };
    await dataRef.update(obj);
  }
  return obj;
}

export async function updatePriestById(id, data) {
  const entryRef = fire.firestore().collection(`person`);
  const entryData = await entryRef.where("uid", "==", id).get();
  const result = [];
  const lastUpdate = new Date().getTime();

  entryData.forEach(async (i) => {
    const item = i.data();
    //get the id
    result.push({ uid: item.uid, lastUpdate, ...data });
    await entryRef.doc(item.uid).update({ uid: item.uid, lastUpdate, ...data });
  });

  return result[0];
}
