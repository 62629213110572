import React, { useContext } from "react";
import { ArchAssemblyContext } from "./archAssemblyReducer";

const MoveTwoResponseDetail = ({ responseId, themeNum, onBack }) => {
  const { assemblyState } = useContext(ArchAssemblyContext);
  const { reflections } = assemblyState;

  const response = reflections.find((resp) => resp.id === responseId);

  if (!response) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="p-6 max-w-2xl bg-white shadow-lg rounded-lg">
          <h2 className="text-xl font-bold mb-4 text-center">Response Not Found</h2>
          <p className="text-gray-600 text-center">The selected response does not exist.</p>
          <button
            className="w-full mt-4 py-2 bg-orange-600 text-white font-semibold rounded-lg shadow hover:bg-orange-700 transition"
            onClick={() => onBack(themeNum)}
          >
            Back to Responses
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="p-6 max-w-2xl bg-white shadow-lg rounded-lg">
        <h2 className="text-xl font-bold mb-4 text-center">Reflection</h2>
        <div 
          className="max-h-96 overflow-y-auto p-4 bg-gray-100 rounded-lg"
          style={{
            wordBreak: "break-word",  
            overflowWrap: "break-word", 
            whiteSpace: "normal", 
          }}
        >
          <p className="text-gray-800">{response.response}</p>
        </div>

        {/* ✅ Back Button retains themeNum */}
        <button
          className="btn w-100 mt-4"
          style={{ backgroundColor: "#E08338", color: "white" }}
          onClick={() => onBack(themeNum)}
        >
          Back to Responses
        </button>
      </div>
    </div>
  );
};

export default MoveTwoResponseDetail;
