import React, { Component } from "react";
import { Image } from "react-bootstrap";
import {
  getParishCompleteNameById,
  getParishIdByCode,
} from "../../services/parish";
import { isValidParish } from "../../services/validation";
import catholic200logo from "../../modules/catholic200sg/img/catholic200sg.png";
import { Link } from "react-router-dom";
import { enableFestival } from "../../services/settings";
import { withRouter } from "react-router-dom";
import { getFestivalUrl } from "../../utils/url";
import arch from "../../img/logo/logo_archdiocese_white.png";
import blessedsacrament from "../../img/parish/200/blessedsacrament.jpg";
import cathedral from "../../img/parish/200/cathedral.jpg";
import christtheking from "../../img/parish/200/christtheking.jpg";
import divinemercy from "../../img/parish/200/divinemercy.jpg";
import holycross from "../../img/parish/200/holycross.jpg";
import holyfamily from "../../img/parish/200/holyfamily.jpg";
import holyspirit from "../../img/parish/200/holyspirit.jpg";
import holytrinity from "../../img/parish/200/holytrinity.jpg";
import immaculateheartofmary from "../../img/parish/200/immaculateheartofmary.jpg";
import ladyoflourdes from "../../img/parish/200/ladyoflourdes.jpg";
import nativityofblessedvirgin from "../../img/parish/200/nativityofblessedvirgin.jpg";
import perpertualsuccour from "../../img/parish/200/perpertualsuccour.jpg";
import queenofpeace from "../../img/parish/200/queenofpeace.jpg";
import starofthesea from "../../img/parish/200/starofthesea.jpg";
import risenchrist from "../../img/parish/200/risenchrist.jpg";
import sacredheart from "../../img/parish/200/sacredheart.jpg";
import novena from "../../img/parish/200/novena.jpg";
import stanne from "../../img/parish/200/stanne.jpg";
import stanthony from "../../img/parish/200/stanthony.jpg";
import stbernadette from "../../img/parish/200/stbernadette.jpg";
import stfrancisassisi from "../../img/parish/200/stfrancisassisi.jpg";
import stfrancisxavier from "../../img/parish/200/stfrancisxavier.jpg";
import stignatius from "../../img/parish/200/stignatius.jpg";
import stjosephbukittimah from "../../img/parish/200/stjosephbukittimah.jpg";
import stjosephvictoria from "../../img/parish/200/stjosephvictoria.jpg";
import stmaryofangels from "../../img/parish/200/stmaryofangels.jpg";
import stmichael from "../../img/parish/200/stmichael.jpg";
import ststephen from "../../img/parish/200/ststephen.jpg";
import stteresa from "../../img/parish/200/stteresa.jpg";
import stvincentdepaul from "../../img/parish/200/stvincentdepaul.jpg";
import stspeterandpaul from "../../img/parish/200/stspeterandpaul.jpg";
import transfiguration from "../../img/parish/200/transfiguration.jpg";


class ParishLogo extends Component {
  images = [
    { id: 0, photo: arch},
    { id: 1, photo: blessedsacrament},
    { id: 2, photo: cathedral},
    { id: 3, photo: christtheking},
    { id: 4, photo: divinemercy},
    { id: 5, photo: holycross},
    { id: 6, photo: holyfamily},
    { id: 7, photo: holyspirit},
    { id: 8, photo: holytrinity},
    { id: 9, photo: immaculateheartofmary},
    { id: 10, photo: ladyoflourdes},
    { id: 11, photo: nativityofblessedvirgin},
    { id: 12, photo: perpertualsuccour},
    { id: 13, photo: queenofpeace},
    { id: 14, photo: starofthesea},
    { id: 15, photo: risenchrist},
    { id: 16, photo: sacredheart},
    { id: 17, photo: novena},
    { id: 18, photo: stanne},
    { id: 19, photo: stanthony},
    { id: 20, photo: stbernadette},
    { id: 21, photo: stfrancisassisi},
    { id: 22, photo: stfrancisxavier},
    { id: 23, photo: stignatius},
    { id: 24, photo: stjosephbukittimah},
    { id: 25, photo: stjosephvictoria},
    { id: 26, photo: stmaryofangels},
    { id: 27, photo: stmichael},
    { id: 28, photo: ststephen},
    { id: 29, photo: stteresa},
    { id: 30, photo: stvincentdepaul},
    { id: 31, photo: stspeterandpaul},
    { id: 32, photo: transfiguration},
    { id: 33, photo: arch},
  ];
  constructor(props) {
    super(props);
    const { parish, parishId, parishCode } = props;
    this.state = {
      parish,
      parishId,
      parishCode,
    };
  }
  getImageDisplay = (id) => {
    let imagedisplay;
    let parishId = parseInt(id);
    if (!isValidParish(parishId)) {
      parishId = 33;
    }
    [imagedisplay] = this.images.filter((img) => img.id === parseInt(parishId));
    return imagedisplay.photo;
  };
  getUniqueParishId = () => {
    const { parish, parishId, parishCode } = this.props;

    let id;
    if (parishId) {
      id = parishId;
    } else if (parishCode) {
      id = getParishIdByCode(parishCode);
    } else if (parish) {
      id = parish._id;
    } else {
      id = 33;
    }
    return parseInt(id);
  };
  getNameDisplay = (id) => {
    let display = (
      <h4 className="pt-1 text-center text-muted">
        <span className="font-italic">my</span>CatholicSG
      </h4>
    );
    if (!isValidParish(id)) {
      return display;
    }
    if (id) {
      display = getParishCompleteNameById(id);
      //check if need to split
      if (display.indexOf("(") !== -1) {
        let first = display.split("(");
        let second = first[1].split(")");
        display = this.props.isSmall ? (
          ""
        ) : (
          <React.Fragment>
            <h5 className="pt-1 mb-1 text-center text-dark">{first[0]}</h5>
            <h5 className="text-center text-dark">({second[0]})</h5>
          </React.Fragment>
        );
      } else {
        display = this.props.isSmall ? (
          ""
        ) : (
          <h4 className="pt-1 text-center text-dark">{display}</h4>
        );
      }
    }
    return display;
  };

  handleClick = () => {
    this.props.history.push(getFestivalUrl());
  };

  render() {
    let id = parseInt(this.getUniqueParishId());
    let link = "/";
    if (this.props.parishCode) {
      link = `parish/${this.props.parishCode}`;
    }
    return (
      <nav
        className={
          this.props.isSmall
            ? "text-center bg-white flex-column justify-content-center"
            : "text-center bg-white flex-column py-3"
        }
      >
        {link === "/" ? (
          <Image
            src={this.getImageDisplay(id)}
            width={this.props.isSmall ? "80px" : "100px"}
            height={this.props.isSmall ? "80px" : "100px"}
            className="shadow-sm"
            alt=""
            rounded
          />
        ) : (
          <Link
            className={
              this.props.isSmall
                ? "navbar-brand mx-auto"
                : "navbar-brand mx-auto pb-2"
            }
            to={link}
          >
            <Image
              src={this.getImageDisplay(id)}
              width={this.props.isSmall ? "80px" : "100px"}
              height={this.props.isSmall ? "80px" : "100px"}
              className="shadow-sm"
              alt=""
              rounded
            />
          </Link>
        )}
        {this.getNameDisplay(id)}
        {false && (
          <div className="text-center">
            {enableFestival() ? (
              <div className="pointer" onClick={this.handleClick}>
                <Image
                  src={catholic200logo}
                  width={this.props.isSmall ? "100px" : "150px"}
                  style={{ marginTop: "-10px" }}
                  alt=""
                />
              </div>
            ) : (
              <a
                className="my-0 pb-0"
                href="https://catholic200.sg"
                rel="noopener noreferrer"
                target="_blank"
              >
                <Image
                  src={catholic200logo}
                  width="100px"
                  style={{ marginTop: "-10px" }}
                  alt=""
                />
              </a>
            )}
          </div>
        )}
      </nav>
    );
  }
}

export default withRouter(ParishLogo);
