import React, { useContext, useEffect, useState, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import Moment from "react-moment";

import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

import NotificationImportantIcon from "@material-ui/icons/NotificationImportant";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import AssignmentTurnedInOutlinedIcon from "@material-ui/icons/AssignmentTurnedInOutlined";

import withLayout from "../components/Layout";
import {
  useActiveRegistration,
  RegistrationDatum,
  RegistrationStatus,
  useGuardian,
  useWardSelection,
  useMediaQuery,
} from "../hooks/hooks";
import AppGlobalContext from "../../../../AppGlobalContext";

import { buttonDictionary } from "../services/routeInfo";
import {
  guardianRegistrationStatusDictionary,
  childRegistrationStatusDictionary,
} from "../services/registrationStatus";
import { getPaymentStatus } from "../services/payments";
import ParishLogoBlock from "../../../parish/parishLogoBlock";

import moment from "moment-timezone";

/** @type {Record<string, string | JSX.Element>} */
const alertDictionary = {
  pendingAnnualContactUpdate: (
    <>
      Please update your contact details on this{" "}
      <Alert.Link as={Link} to="/catch/contactupdate">
        link
      </Alert.Link>
      !
    </>
  ),
  pendingAnnualPayment: (
    <>
      Class fund for this year is due!{" "}
      <Alert.Link as={Link} to="/catch/classfund">
        View in link
      </Alert.Link>
      .
    </>
  ),
};

/**
 * @param {ButtonProps} buttonProps
 */
function MenuButton({ label: title, Icon, link, disabled }) {
  const curComponent = (
    <Col className="px-1 py-1" lg={6}>
      <Link to={link}>
        <Button
          className="w-100 py-3 catch-menu-button"
          variant="outline-dark"
          style={{ lineHeight: 1.1 }}
          disabled={disabled}
        >
          {!!Icon && <Icon style={{ fontSize: 48 }} />}
          <br />
          <small className="font-weight-bold">{title.toUpperCase()}</small>
        </Button>
      </Link>
    </Col>
  );

  return curComponent;
}

/**
 * @param {"empty" | "unregistered" | RegistrationStatus} registrationStatus
 * @param {RegistrationDatum} [registrationDatum]
 */
function detectDatumIssues(registrationStatus, registrationDatum) {
  if (registrationStatus === "unregistered" || registrationStatus === "empty")
    return { type: "none", payload: <></> };

  const { selectedParishId: parishId, contactUpdateHistory } =
    registrationDatum;

  // const parish = parishesConfig.find(({ id }) => id === parishId);

  // if (parish === undefined) {
  //   return { type: "critical", payload: <>Parish not found.</> };
  // }

  // ALERT CHECKS GO HERE FROM TOP TO BOTTOM
  /** @type {string[]} */
  const alerts = [];

  // TODO: Uncomment for Dec release
  // if (
  //   registrationStatus === "accepted" &&
  //   !contactUpdateHistory.some(
  //     (record) =>
  //       parish.currentYear ===
  //       moment(record.updatedAt.toDate()).tz("Asia/Singapore").year().toString()
  //   )
  // )
  //   alerts.push("pendingAnnualContactUpdate");
  // const paymentStatus = getPaymentStatus({ registrationDatum, parish });
  // if (
  //   (registrationStatus === "accepted" && paymentStatus === "pending") ||
  //   paymentStatus === "requestwaive"
  // )
  //   alerts.push("pendingAnnualPayment");

  if (alerts.length)
    return {
      type: "alerts",
      payload: (
        <div className="w-100">
          <Alert variant="danger" className="w-100 mt-0 mb-2">
            <Alert.Heading
              className="d-flex align-items-center"
              style={{ gap: "0.25rem" }}
            >
              <NotificationImportantIcon /> Alerts
            </Alert.Heading>
            <ul>
              {alerts.map((key) => (
                <li key={key}>{alertDictionary[key]}</li>
              ))}
            </ul>
          </Alert>
        </div>
      ),
    };

  return { type: "none", payload: <></> };
}

function UserMenu({ location }) {
  const { user } = useContext(AppGlobalContext);
  const activeRegistrationState = useActiveRegistration();
  const { isGuardian, wardsData } = useGuardian();
  const { selectedWardIndex, setSelectedWardIndex } = useWardSelection();
  const isGteSmWidth = useMediaQuery("(min-width: 576px)");

  // console.log("Render: User Menu");

  const { data: registrationDatum, status: registrationStatus } =
    activeRegistrationState;

  const { selectedParishId: parishId = -1 } = registrationDatum ?? {};

  const { type: issueType, payload: issuePayload } = detectDatumIssues(
    registrationStatus,
    registrationDatum
  );

  const history = useHistory();
  const callBack = useCallback(
    () => history.push({ pathname: "/catch" }),
    [history]
  );

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showTransferModal, setShowTransferModal] = useState(false);
  const [showUpdateContactModal, setShowUpdateContactModal] = useState(false);
  const [showGuardianLinkModal, setGuardianLinkModal] = useState(false);

  const handleClose = () => {
    callBack();
    setShowSuccessModal(false);
  };
  const handleShow = () => setShowSuccessModal(true);

  const handleCloseConfirmation = () => {
    setShowConfirmationModal(false);
    callBack();
  };
  const handleShowConfirmation = () => setShowConfirmationModal(true);

  const handleCloseTransfer = () => {
    setShowTransferModal(false);
    callBack();
  };
  const handleShowTransfer = () => setShowTransferModal(true);

  const handleCloseUpdateContact = () => {
    setShowUpdateContactModal(false);
    callBack();
  };
  const handleShowUpdateContact = () => setShowUpdateContactModal(true);

  const handleCloseGuardianLink = () => {
    setGuardianLinkModal(false);
    callBack();
  };
  const handleShowGuardianLink = () => setGuardianLinkModal(true);

  const {
    registerSuccess = false,
    confirmationSuccess = false,
    modifyDate = new Date(),
    transferSuccess = false,
    updateContactSuccess = false,
    guardianLinkSuccess = false,
  } = location;

  useEffect(() => {
    if (registerSuccess) {
      handleShow();
    }
  }, [registerSuccess]);

  useEffect(() => {
    if (confirmationSuccess) {
      handleShowConfirmation();
    }
  }, [confirmationSuccess]);

  useEffect(() => {
    if (transferSuccess) {
      handleShowTransfer();
    }
  }, [transferSuccess]);

  useEffect(() => {
    if (updateContactSuccess) {
      handleShowUpdateContact();
    }
  }, [updateContactSuccess]);

  useEffect(() => {
    if (guardianLinkSuccess) {
      handleShowGuardianLink();
    }
  }, [guardianLinkSuccess]);

  if (issueType === "critical") return issuePayload;

  // TODO: Remove for Dec release
  const disabledPages = [
    // "contactUpdate",
    // "parishTransfer",
    "confirmation",
    // "classFund",
  ];

  const { message, userLabel, pages, bg, text } = isGuardian
    ? guardianRegistrationStatusDictionary[registrationStatus]
    : childRegistrationStatusDictionary[registrationStatus];

  return (
    <>
      <Container className="w-100">
        <Row className="w-100 justify-content-center">
          <h4>
            Welcome, <span className="text-danger">{user.fullname}</span>!
          </h4>
        </Row>
        <Row className="w-100">
          {isGuardian && (
            <Container>
              {!wardsData.length ? (
                // TODO: Link to Guardian Control route
                <div className="text-center">
                  You have no linked wards.
                  <br />
                  Go to{" "}
                  <Link to="/catch/guardian" className="font-weight-bold">
                    Guardian Control
                  </Link>{" "}
                  to begin!
                </div>
              ) : (
                <Form.Group as={Row} className="my-0">
                  <Form.Label
                    column
                    md={2}
                    sm={3}
                    xs={4}
                    className="my-auto text-center"
                  >
                    <h5 className="my-0">Current Child</h5>
                  </Form.Label>
                  <Col md={10} sm={9} xs={8} className="d-flex">
                    <Form.Control
                      className="m-auto"
                      as="select"
                      size="lg"
                      value={selectedWardIndex}
                      onChange={(e) => setSelectedWardIndex(e.target.value)}
                    >
                      <option value={-1} disabled hidden className="text-muted">
                        Select a child...
                      </option>
                      {wardsData.map(({ name, uid }, index) => (
                        <option key={`${uid}-${index}`} value={index}>
                          {name}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                </Form.Group>
              )}
            </Container>
          )}
        </Row>
      </Container>
      <hr className="w-100" />
      {issueType === "alerts" && issuePayload}
      <Container className="w-100">
        <Row className="align-items-center">
          <Col md={5}>
            <Card className="text-center" border={bg}>
              <Card.Header className={`text-${text} bg-${bg}`}>
                <h5 className="m-0 font-weight-bold">
                  {userLabel.toUpperCase()}
                </h5>
              </Card.Header>
              <Card.Body>
                <ParishLogoBlock parishId={parishId} />
              </Card.Body>
              <Card.Footer className={`text-${text} bg-${bg}`}>
                <span className="font-italic">{message}</span>
              </Card.Footer>
            </Card>
          </Col>
          <Col md={7} className={isGteSmWidth ? "border-left" : "mt-5"}>
            <Container>
              <Row>
                <Col>
                  <h5>What would you like to do?</h5>
                </Col>
              </Row>
              <Row>
                {pages.map((key) => (
                  <MenuButton
                    {...buttonDictionary[key]}
                    disabled={disabledPages.includes(key)}
                  />
                ))}
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
      <Modal show={showSuccessModal} onHide={handleClose} centered>
        <Modal.Body>
          <Container>
            <Row className="justify-content-end">
              <CloseOutlinedIcon
                className="text-dark"
                style={{ cursor: "pointer" }}
                onClick={handleClose}
              />
            </Row>
            <Row className="justify-content-center">
              <AssignmentTurnedInOutlinedIcon
                className="my-4 text-success"
                style={{ fontSize: "60px" }}
              />
            </Row>
            <Row className="mb-3 justify-content-center text-center">
              <h2>Thank you for registering!</h2>
            </Row>
            <Row className="mb-2 justify-content-center text-center">
              Your registration has been submitted to your Parish for review.
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
      <Modal
        show={showConfirmationModal}
        onHide={handleCloseConfirmation}
        centered
      >
        <Modal.Body>
          <Container>
            <Row className="justify-content-end">
              <CloseOutlinedIcon
                className="text-dark"
                style={{ cursor: "pointer" }}
                onClick={handleCloseConfirmation}
              />
            </Row>
            <Row className="justify-content-center">
              <AssignmentTurnedInOutlinedIcon
                className="my-4 text-success"
                style={{ fontSize: "60px" }}
              />
            </Row>
            <Row className="mb-3 justify-content-center text-center">
              <h2>Thank you for filling up the form!</h2>
            </Row>
            <Row className="mb-2 justify-content-center text-center">
              You may modify and submit changes before
              <Moment
                className="pl-1 font-weight-bold"
                date={modifyDate}
                format="DD MMM YYYY"
              />
              .
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
      <Modal show={showTransferModal} onHide={handleCloseTransfer} centered>
        <Modal.Body>
          <Container>
            <Row className="justify-content-end">
              <CloseOutlinedIcon
                className="text-dark"
                style={{ cursor: "pointer" }}
                onClick={handleCloseTransfer}
              />
            </Row>
            <Row className="justify-content-center">
              <AssignmentTurnedInOutlinedIcon
                className="my-4 text-success"
                style={{ fontSize: "60px" }}
              />
            </Row>
            <Row className="mb-3 justify-content-center text-center">
              <h2>Request Submitted!</h2>
            </Row>
            <Row className="mb-2 justify-content-center text-center">
              Your transfer request has been submitted to your Parish for
              review.
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
      <Modal
        show={showUpdateContactModal}
        onHide={handleCloseUpdateContact}
        centered
      >
        <Modal.Body>
          <Container>
            <Row className="justify-content-end">
              <CloseOutlinedIcon
                className="text-dark"
                style={{ cursor: "pointer" }}
                onClick={handleCloseUpdateContact}
              />
            </Row>
            <Row className="justify-content-center">
              <AssignmentTurnedInOutlinedIcon
                className="my-4 text-success"
                style={{ fontSize: "60px" }}
              />
            </Row>
            <Row className="mb-3 justify-content-center text-center">
              <h2>Contact Successfully Updated!</h2>
            </Row>
            <Row className="mb-2 justify-content-center text-center">
              Your contact details have been updated successfully.
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
      <Modal
        show={showGuardianLinkModal}
        onHide={handleCloseGuardianLink}
        centered
      >
        <Modal.Body>
          <Container>
            <Row className="justify-content-end">
              <CloseOutlinedIcon
                className="text-dark"
                style={{ cursor: "pointer" }}
                onClick={handleCloseGuardianLink}
              />
            </Row>
            <Row className="justify-content-center">
              <AssignmentTurnedInOutlinedIcon
                className="my-4 text-success"
                style={{ fontSize: "60px" }}
              />
            </Row>
            <Row className="mb-3 justify-content-center text-center">
              <h2>Child successfully linked!</h2>
            </Row>
            <Row className="mb-2 justify-content-center text-center">
              You may now proceed to register your child.
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default withLayout(UserMenu, "menu");
