import {
  RegistrationDatum,
  ParishConfig,
  ProgrammeConfig,
} from "../hooks/hooks";
import { LookupConfig } from "../admin/hooks/hooks";

/**
 * @typedef Level
 * @prop {number} id
 * @prop {string} name
 */

/** @type {Level[]} */
export const levelRef = [
  { name: "PL", id: 3 },
  { name: "NU", id: 4 },
  { name: "K1", id: 5 },
  { name: "K2", id: 6 },
  { name: "L1", id: 7 },
  { name: "L2", id: 8 },
  { name: "L3", id: 9 },
  { name: "L4", id: 10 },
  { name: "L5", id: 11 },
  { name: "L6", id: 12 },
  { name: "L7", id: 13 },
  { name: "L8", id: 14 },
  { name: "L9", id: 15 },
  { name: "L10", id: 16 },
  { name: "L11", id: 17 },
  { name: "L12", id: 18 },
];

/**
 * @param {number} _id
 */
export function levelIdToName(_id) {
  return levelRef.find(({ id }) => _id === id)?.name;
}

/**
 * @param {RegistrationDatum}
 * @param {Date} date
 */
export function getLevelAtDate({ level: { id, year } }, date) {
  const computedLevel = id + date.getFullYear() - +year;
  // console.log(computedLevel);
  return computedLevel;
}

/**
 * @typedef GetCurrentLevelProps
 * @prop {RegistrationDatum} registrationDatum The registration document object
 * @prop {ParishConfig} [parishConfig] Defined when user is parent, obtained via useParishesConfig hook and .find()
 * @prop {LookupConfig} [lookupConfig] Defined when user is admin, obtained via useLookupConfig hook
 */

/**
 * @param {GetCurrentLevelProps} props
 * @returns {number}
 */
export function getCurrentLevel({
  registrationDatum: {
    level: registrationLevel,
    registrationYear,
    selectedParishId,
    programmeType,
    retainedYears,
  },
  parishConfig,
  lookupConfig,
}) {
  // Invalid conditions
  if (
    !registrationYear ||
    !selectedParishId ||
    !programmeType ||
    !(parishConfig || lookupConfig) // At least one must be defined, depending on whether user is parent or admin
  )
    return null;

  /** @type {ProgrammeConfig | undefined} */
  let programme;
  if (!!lookupConfig)
    programme = lookupConfig(
      selectedParishId,
      typeof programmeType === "string" ? programmeType : programmeType.id
    )?.programme;
  else if (typeof programmeType === "string")
    programme = parishConfig.programmes.find(({ id }) => id === programmeType);
  else programme = parishConfig.programmes.find(({ id }) => id === programmeType?.id);

  const catecheticalYear = programme?.catecheticalYear;
  if (!catecheticalYear) return null;

  const numRetainedYears =
    retainedYears?.filter((year) => year < catecheticalYear)?.length ?? 0;

  const level =
    registrationLevel - registrationYear + catecheticalYear - numRetainedYears;

  return level;
}
