import React, { useContext, useState, useEffect } from "react";
import { ArchAssemblyContext } from "./archAssemblyReducer";
import themes from "./themes.json";

const GroupThemeResponses = () => {
  const { assemblyState, assemblyStateDispatch, handleEditSubmit } = useContext(ArchAssemblyContext);
  const { groupSubmissions, delegateProfile, themeNum } = assemblyState;
  const themeName = themes[themeNum] || "Unknown Theme";
  const isGroupTheme = themeNum === delegateProfile?.themeNum;
  const [visibleCount, setVisibleCount] = useState(5);
  const [editingStates, setEditingStates] = useState({});
  const [updatedResponses, setUpdatedResponses] = useState({});
  const isFacil = delegateProfile?.isFacil;

  // useEffect(() => {
  //   console.log("Filtering responses for themeNum:", themeNum);
  //   console.log("All responses:", groupSubmissions);
  // }, [themeNum, groupSubmissions]);

  const responsesForTheme = groupSubmissions
  .filter(sub => Number(sub.themeNum) === Number(themeNum)) // Ensure both are numbers
  .sort((a, b) => {
    // Prioritize the current user's group
    if (a.submittedByGroup === delegateProfile.groupNum && b.submittedByGroup !== delegateProfile.groupNum) {
      return -1; // Move 'a' up
    }
    if (b.submittedByGroup === delegateProfile.groupNum && a.submittedByGroup !== delegateProfile.groupNum) {
      return 1; // Move 'b' up
    }
    // Otherwise, sort by date (most recent first)
    return new Date(b.createdAt) - new Date(a.createdAt);
  });

  const handleReadMore = (responseId) => {
    assemblyStateDispatch({
      type: "SET_RESPONSE_DETAIL",
      payload: { responseId },
    });
  };

  return (
    <div className="p-4 max-w-3xl mx-auto">
      <h2 className="text-xl font-bold mb-4">{themeName}</h2>
      <p className="text-gray-600">
        Here are the responses submitted by different groups for this theme.
      </p>

      {/* Display Responses */}
      <div className="mt-4">
      {responsesForTheme.length > 0 ? (
  responsesForTheme.map((submission, index) => {
    const isUserGroup = Number(submission.submittedByGroup) === Number(delegateProfile.groupNum);
    const isEditing = editingStates[submission.id] || false;
    const updatedResponse = updatedResponses[submission.id] || submission.response;

    
    return (
      <div key={index} className="mt-2 p-3 bg-gray-50 rounded-lg shadow relative">
<div className="d-flex justify-content-between align-items-center">
<h4 className="text-md font-medium">Group {submission.submittedByGroup}</h4>
    
    {isUserGroup && (
      <span 
      style={{ 
        backgroundColor: "#FCE3C2", 
        color: "#E08338", 
        padding: "4px 8px", 
        borderRadius: "999px" 
      }}
    >
      Your answer
    </span>
    )}
  </div>

      {/* Editable Text or Normal View */}
    {!isEditing ? (
      <p
        className="text-gray-800"
        style={{
          wordWrap: "break-word",
          overflowWrap: "break-word",
          wordBreak: "break-word",
          whiteSpace: "pre-wrap",
        }}
      >
        {submission.response.length > 100
          ? submission.response.substring(0, 100) + "..."
          : submission.response}
      </p>
    ) : (
      isFacil && (
        <textarea
          className="w-full border rounded p-2 mt-2"
          rows="5"
          style={{ minHeight: "100px", width: "100%", backgroundColor: "white", color: "black" }}
          value={updatedResponses[submission.id] !== undefined ? updatedResponses[submission.id] : submission.response}
          onChange={(e) =>
            setUpdatedResponses({ ...updatedResponses, [submission.id]: e.target.value })
          }
        />
      )
    )}

    {/* Edit & Save Buttons (Only for Facils) */}
    {isUserGroup && isFacil && (
      <div className="d-flex justify-content-end mt-2">
        {!isEditing ? (
          <button
            className="px-4 py-2 border rounded"
            style={{
              backgroundColor: "white",
              color: "#E08338",
              border: "2px solid #E08338",
              fontSize: "14px",
              cursor: "pointer",
            }}
            onClick={() => setEditingStates({ ...editingStates, [submission.id]: true })}
          >
            Edit
          </button>
        ) : (
          <button
            className="px-4 py-2 text-white rounded"
            style={{
              backgroundColor: updatedResponses[submission.id]?.trim() === "" ? "#ccc" : "#E08338",
              fontSize: "14px",
              border: "none",
              cursor: updatedResponses[submission.id]?.trim() === "" ? "not-allowed" : "pointer",
            }}
            disabled={updatedResponses[submission.id]?.trim() === ""}
            onClick={() => {
              handleEditSubmit(submission.id, updatedResponses[submission.id]); 
              setEditingStates({ ...editingStates, [submission.id]: false }); 
            }}
          >
            Save Changes
          </button>
        )}
      </div>
    )}

  

        {/* Response Text */}
        {/* <p className="text-gray-800">
          {submission.response.length > 100
            ? submission.response.substring(0, 100) + "..."
            : submission.response}
        </p> */}
        
        {/* Read More Button */}
        {submission.response.length > 100 && (
          <button
            className="text-blue-500 text-sm mt-1"
            onClick={() => handleReadMore(submission.id)}
          >
            Read more
          </button>
        )}
      </div>
    );
  })
) : (
  <p className="text-gray-500 mt-2">No responses yet.</p>
)}

</div>


      <div className="mt-4 w-full">
        {visibleCount < responsesForTheme.length && (
          <button
            onClick={() => setVisibleCount((prev) => prev + 5)}
            className="w-full py-3 text-gray-900 bg-white border border-black rounded-lg font-medium text-center"
          >
            Load more...
          </button>
        )}
      </div>

      {/* Back Button */}
      <div className="d-flex justify-content-center">
      <button
        className="btn btn-link mt-3"
        style={{ textDecoration: "underline", color: "black" }}
        onClick={() => assemblyStateDispatch({ type: "SET_PAGE", payload: { page: "GROUP_RESPONSES" } })}
      >
        Go back to topic listing
      </button>
      </div>
    </div>
  );
};

export default GroupThemeResponses;
