import React, { useContext } from "react";
import { Image } from "react-bootstrap";
import { ArchAssemblyContext } from "./archAssemblyReducer";
import { useEffect } from "react";
import themes from "./themes.json";

import assemblyLogo from "./archassembly25-logo.jpeg";

const AssemblyStartPage = () => {
  const { assemblyState, assemblyStateDispatch } =
    useContext(ArchAssemblyContext);
  const { delegateProfile, settings } = assemblyState;
  const {
    isMovementOneOpen = false,
    isMovementTwoOpen = false,
    isMovementThreeOpen = false,
  } = settings;

  return (
    <div className="d-flex flex-column">
      <div
        className="d-flex w-100 justify-content-center"
        style={{
          width: "100%",
          objectFit: "cover",
          overflow: "hidden",
        }}
      >
        <Image src={assemblyLogo} width="100%" height="100%" />
      </div>
      {/* User Info */}
      <h2 className="mb-3">Welcome, {delegateProfile.name || "Delegate"}!</h2>
      <div className="text-muted">
        <p>📅 {delegateProfile.eventDate || "1st March 8AM"}</p>
        <p>
          📍 {delegateProfile.venue || "St Joseph Institution, 38 Malcolm Rd"}
        </p>
        <p>
          👥 T{delegateProfile.themeNum}-{delegateProfile.groupNum} |{" "}
          {themes[delegateProfile.themeNum]}
        </p>
      </div>

      {/* Preparation Section */}
      <h4 className="mt-4">Preparation</h4>
      <button
        className="btn w-100 py-3 my-2"
        style={{
          backgroundColor: "#E08338", // Base orange color
          color: "#fff", // White text color
          borderRadius: "10px", // Rounded corners
        }}
        onClick={() =>
          assemblyStateDispatch({
            type: "SET_PAGE",
            payload: { page: "ASSEMBLY_RESOURCES" },
          })
        }
      >
        Read about the Assembly
      </button>

      {/* Assembly Day Section */}
      <h4 className="mt-4">On the Assembly day</h4>
      <button
        className="btn w-100 py-3 my-2"
        style={{
          backgroundColor: isMovementOneOpen ? "#E08338" : "#D3D3D3",
          color: isMovementOneOpen ? "#fff" : "#888",
          borderRadius: "10px",
        }}
        onClick={() =>
          isMovementOneOpen
            ? assemblyStateDispatch({
                type: "SET_PAGE",
                payload: { page: "MOVEMENT_1" },
              })
            : null
        }
        disabled={!isMovementOneOpen}
      >
        Movement 1
      </button>

      <button
        className="btn w-100 py-3 my-2"
        style={{
          backgroundColor: isMovementTwoOpen ? "#E08338" : "#D3D3D3",
          color: isMovementTwoOpen ? "#fff" : "#888",
          borderRadius: "10px",
        }}
        onClick={() =>
          isMovementTwoOpen
            ? assemblyStateDispatch({
                type: "SET_PAGE",
                payload: { page: "MOVEMENT_2" },
              })
            : null
        }
        disabled={!isMovementTwoOpen}
      >
        Movement 2
      </button>

      <button
        className="btn w-100 py-3 my-2"
        style={{
          backgroundColor: isMovementThreeOpen ? "#E08338" : "#D3D3D3",
          color: isMovementThreeOpen ? "#fff" : "#888",
          borderRadius: "10px",
        }}
        onClick={() =>
          isMovementThreeOpen
            ? assemblyStateDispatch({
                type: "SET_PAGE",
                payload: { page: "MOVEMENT_3" },
              })
            : null
        }
        disabled={!isMovementThreeOpen}
      >
        Movement 3
      </button>
    </div>
  );
};

export default AssemblyStartPage;
