import { getMassDateTime } from "../utils/utils";
import fire from "./fire";

/* Action Types */
export const EVENT_HUB_BOOK_START = 0;
export const EVENT_HUB_BOOK_END = 1;
export const EVENT_HUB_DELETE_START = 2;
export const EVENT_HUB_DELETE_END = 3;
export const EVENT_HUB_DELETE_SELECTED = 4;
export const EVENT_HUB_DELETE_CONFIRMED = 5;
export const EVENT_HUB_DELETE_CANCELLED = 6;
/* PFS: See pfs code */

export async function logEvent(event, data) {
  fire.analytics().logEvent(event, data); 
}

export async function logAction(uid, actionType, actionObj = {}) {
  const logRef = fire.firestore().collection("actions").doc();
  const created = new Date().getTime();

  const docData = {
    id: uid,
    actionType: actionType,
    created: created,
    obj: actionObj,
  };

  await logRef.set(docData);
  return docData;
}

export async function getLogActionByID(uid = null, limit = 1) {
  if (!uid) {
    return [];
  }

  const db = fire.firestore();

  const actions = await db
    .collection("actions")
    .where("id", "==", uid)
    .orderBy("created", "desc")
    .limit(limit)
    .get();

  const list = [];
  actions.forEach((doc) => {
    const data = doc.data();
    list.push(data);
  });

  return list;
}

export async function getLogByActionType(
  uid = null,
  actionType = "",
  limit = 1
) {
  if (!uid) {
    return [];
  }

  const db = fire.firestore();

  const actions = await db
    .collection("actions")
    .where("id", "==", uid)
    .where("actionType", "==", actionType)
    .orderBy("created", "desc")
    .limit(limit)
    .get();

  const list = [];
  actions.forEach((doc) => {
    const data = doc.data();
    list.push(data);
  });
  return list;
}

export async function logMessage(where, data) {
  const logMessage = fire.functions("asia-east2").httpsCallable("logMessage");

  const date = new Date();
  logMessage({
    email: data.email,
    code: data.code,
    message: data.message,
    where: where,
    timestamp: `${date.toDateString()} ${getMassDateTime(date)}`,
  });
}
