import React, { useContext, useState, useEffect } from "react";
import { ArchAssemblyContext } from "./archAssemblyReducer";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline"; // ✅ Importing the tick icon
import "bootstrap/dist/css/bootstrap.min.css"; // Ensure Bootstrap is imported

const MovementThree = () => {
  const { submitMovementThree, assemblyState, assemblyStateDispatch } =
    useContext(ArchAssemblyContext);
  const { delegateProfile } = assemblyState;

  const [step, setStep] = useState(1);
  const [mcq, setMcq] = useState("");
  const [priorities, setPriorities] = useState(Array(7).fill(0));
  const [response, setResponse] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const [showRatingGuidance, setShowRatingGuidance] = useState(false);

  // useEffect(() => {
  //   console.log("Full Delegate Profile:", delegateProfile);
    
  // }, [delegateProfile]);

  useEffect(() => {
    if (delegateProfile?.userid && assemblyState?.session3Responses) {
      const existingSubmission = assemblyState.session3Responses.find(
        (submission) => submission.submittedBy === delegateProfile.userid
      );
  
      if (existingSubmission) {
        setIsSubmitted(true);
        setMcq(existingSubmission.mcq);
        setPriorities(existingSubmission.priorities);
        setResponse(existingSubmission.recommendation);
      }
    }
  }, [assemblyState.session3Responses, delegateProfile.userid]);  

  const handleSubmit = async () => {
    if (isSubmitLoading || !response.trim()) return; // Prevent multiple clicks & empty responses
  
    setIsSubmitLoading(true); // Disable button immediately
  
    const session3Data = {
      mcq,
      priorities,
      recommendation: response.trim(),
      submittedBy: delegateProfile.userid,
      submittedByName: delegateProfile.name,
      createdAt: new Date().toISOString(),
      isSubmitted: true,
    };
  
    try {
      await submitMovementThree(session3Data);
      setIsSubmitted(true);
      
      assemblyStateDispatch({
        type: "UPDATE_DELEGATE_PROFILE",
        payload: { ...delegateProfile, isSubmitted: true },
      });
  
    } catch (error) {
      console.error("Submission failed:", error);
      alert("An error occurred while submitting. Please try again.");
    } finally {
      setIsSubmitLoading(false); // Re-enable button after success/failure
    }
  };

  if (isSubmitted && !isSubmitLoading) {
    return (
      <div className="container mt-5">
        {/* ✅ Success Icon & Heading */}
        <div className="text-center mb-4">
          <CheckCircleOutlineIcon style={{ fontSize: "4rem", color: "#28a745" }} />
          <h2 className="fw-bold mt-3 text-success">Response Submitted!</h2>
          <p className="text-muted">Thank you for your valuable input.</p>
        </div>
  
        {/* ✅ Stylish Card for Submitted Responses */}
        <div className="card shadow-sm border-0 p-4 w-full max-w-screen-md mx-auto overflow-hidden">
          <h4 className="fw-bold mb-4 text-primary">Your Submitted Responses</h4>

          {/* ✅ Support Level Section */}
          <div className="mb-4">
            <h6 className="fw-bold text-dark">
              To what extent do you support the pastoral priorities as set out in the Schema as a guiding vision for our Church’s mission?
            </h6>
            <div className="text-start">
              <span 
                className="badge px-3 py-2 fs-8 w-full text-center"
                style={{
                  backgroundColor: "#E08338", 
                  color: "white",
                  wordWrap: "break-word",
                  whiteSpace: "normal", 
                  display: "block", 
                  maxWidth: "100%",
                }}
              >
                {[
                  "I strongly support it and am ready to act on it.",
                  "I support most of it and will do what I can to move it forward.",
                  "I have significant concerns and would appreciate further dialogue to reach greater understanding and alignment."
                ][mcq - 1]}
              </span>
            </div>
          </div>

          {/* ✅ Importance for Mission Table */}
          <div className="mb-4 overflow-x-auto">
            <h6 className="fw-bold text-dark">Importance for Mission:</h6>
            <div className="table-responsive w-full">
              <table className="table table-bordered border-2 w-full min-w-max">
                <thead style={{ backgroundColor: "#E08338" }} className="text-white">
                  <tr>
                    <th scope="col" className="fw-bold text-white p-3">Pastoral Priority</th>
                    <th scope="col" className="fw-bold text-white text-center p-3">Priority Level</th>
                  </tr>
                </thead>
                <tbody>
                  {[
                    "Accompaniment and community for all",
                    "Build strong families centred on Christ",
                    "Build unity in diversity",
                    "Enable missionary parishes",
                    "Enhance differentiated co-responsibility for clergy and laity",
                    "Embrace synodality",
                    "Lifelong formation for evangelisation and mission",
                  ].map((label, index) => (
                    <tr key={index}>
                      <td className="fw-medium text-dark p-3 border-2">{label}</td>
                      <td className="text-center p-3 border-2">
                        <span
                          className="badge px-3 py-2 fs-6 shadow"
                          style={{
                            backgroundColor: ["#6c757d", "#f4a261", "#e76f51", "#d62828"][priorities[index]],
                            color: "white",
                            fontWeight: "600",
                            fontSize: "15px",
                            whiteSpace: "normal",
                          }}
                        >
                          {["Not a priority", "Low priority", "Medium priority", "High priority"][priorities[index]]}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
  
          {/* ✅ Additional Comments */}
          <div className="mb-4">
            <h6 className="fw-bold text-dark">
              Are there other priorities not listed in the Schema that you believe are important for us to focus on in the next 10 years?:
            </h6>
            <div 
              className="p-3 rounded border border-2 border-secondary bg-light"
              style={{ minHeight: "50px" }} // ✅ Ensures box remains noticeable even when empty
            >
              {response || <span className="text-muted">No additional comments provided.</span>}
            </div>
          </div>
        </div>
  
        {/* ✅ Return Button */}
        <div className="text-center mt-4">
          <button
              className="btn btn-link mt-3"
              style={{ textDecoration: "underline", color: "black" }}
            onClick={() => assemblyStateDispatch({ type: "SET_PAGE", payload: { page: "HOME" } })}
          >
            Return to Assembly Home
          </button>
        </div>
      </div>
    );
  }
  

  return (
    <div className="container mt-4">
      {/* STEP 1: MCQ Selection */}
      {step === 1 && (
        <>
          <h5 className="fw-bold">Movement 3</h5>
          <h2 className="fw-bold mt-3">Your support</h2>
          <p className="text-muted">
            To what extent do you support the pastoral priorities as set out in the Schema as a guiding vision for our Church’s mission?
          </p>

          {/* MCQ Options */}
          <div className="mt-3">
            {[
              "I strongly support it and am ready to act on it.",
              "I support most of it and will do what I can to move it forward.",
              "I have significant concerns and would appreciate further dialogue to reach greater understanding and alignment.",
            ].map((option, index) => (
              <div
                key={index}
                className={`form-check border border-3 rounded p-3 mb-2 d-flex align-items-center ${
                  mcq === `${index + 1}`
                    ? "border-warning shadow-sm"
                    : "border-dark"
                }`}
                onClick={() => setMcq(`${index + 1}`)}
                style={{ cursor: "pointer" }}
              >
                {/* Custom Radio Button */}
                <div
                  className={`d-flex align-items-center justify-content-center rounded-circle border border-3 me-2 ${
                    mcq === `${index + 1}` ? "border-warning" : "border-dark"
                  }`}
                  style={{ width: "22px", height: "22px", marginTop: "2px" }} /* ✅ Slightly bigger for easier tap on mobile */
                >
                  {mcq === `${index + 1}` && (
                    <div className="bg-warning rounded-circle" 
                        style={{ width: "12px", height: "12px", backgroundColor: "#d47b23" }}>
                    </div>
                  )}
                </div>
                <label className="form-check-label flex-grow-1" style={{ marginLeft: "15px", fontSize: "16px" }}>
                  {option}
                </label>
              </div>
            ))}
          </div>


          {/* Navigation Buttons */}
          <div className="d-flex flex-column align-items-center mt-4">
            <button
              onClick={() => setStep(2)}
              disabled={!mcq}
              className="btn btn-warning text-white w-100 py-2 fw-bold"
              style={{ backgroundColor: "#E08338", color: "white" }}
            >
              Next
            </button>
            <button
              className="btn btn-link mt-3"
              style={{ textDecoration: "underline", color: "black" }}
              onClick={() => assemblyStateDispatch({ type: "SET_PAGE", payload: { page: "HOME" } })}
            >
              Back
            </button>
          </div>
        </>
      )}

      {/* STEP 2: Rating Priorities */}
      {step === 2 && (
        <>
          <h5 className="fw-bold">Movement 3</h5>
          <h2 className="fw-bold mt-3">Rate pastoral priorities</h2>
          <p className="text-muted">
            Please let us know how important you feel each of the pastoral priorities are.
            Drag the sliders to change your answer.
          </p>
          {/* ✅ Expandable "Learn what each rating means" Section */}
          <p
                className="text-primary text-decoration-underline mb-3"
                role="button"
                style={{ cursor: "pointer" }}
                onClick={() => setShowRatingGuidance(!showRatingGuidance)}
              >
                {showRatingGuidance ? "Hide rating guidance" : "Learn what each rating means"}
                <span className="ml-2">{showRatingGuidance ? "▲" : "▼"}</span> 
              </p>

              {/* ✅ Expanding Section */}
              {showRatingGuidance && (
                <div className="border rounded p-3 mb-3 bg-light">
                  <h6 className="fw-bold">High priority</h6>
                  <p className="text-muted">We should do this immediately.</p>

                  <h6 className="fw-bold">Medium priority</h6>
                  <p className="text-muted">We should focus on this in the next few years.</p>

                  <h6 className="fw-bold">Low priority</h6>
                  <p className="text-muted">This is important, but can wait until other needs are met.</p>

                  <h6 className="fw-bold">Not a priority</h6>
                  <p className="text-muted">This has little impact on our mission for now.</p>
                </div>
              )}

          {[
            "Accompaniment and community for all",
            "Build strong families centred on Christ",
            "Build unity in diversity",
            "Enable missionary parishes",
            "Enhance differentiated co-responsibility for clergy and laity",
            "Embrace synodality",
            "Lifelong formation for evangelisation and mission",
          ].map((label, index) => (
            <div className="rounded border p-3 mb-2">
  <label className="fw-medium">{label}</label>
  <style>
    {`
      input[type="range"]::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 16px;
      height: 16px;
      background-color: #E08338;
      border-radius: 50%;
      cursor: pointer;
      margin-top: 12px; /* ✅ Moves the thumb slightly down to match the track */
      }

      input[type="range"]::-moz-range-thumb {
        width: 16px;
        height: 16px;
        background-color: #E08338; /* Orange */
        border-radius: 50%;
        cursor: pointer;
      }
    `}
  </style>
  <div className="position-relative text-center mt-3">
    {/* Slider Track with Dots */}
    <div 
      className="position-absolute" 
      style={{ 
        width: "80%", 
        height: "12px", 
        backgroundColor: "#D3D3D3", 
        borderRadius: "2px", 
        top: "15px", /* ✅ Moves track down slightly */
        left: "50%",
        transform: "translateX(-50%)",
        zIndex: "-1",
        background: `linear-gradient(to right, #E08338 0%, #E08338 ${
          (priorities[index] / 3) * 100
        }%, #D3D3D3 ${(priorities[index] / 3) * 100}%, #D3D3D3 100%)`,
      }}>
    </div>

    {/* Dots on the Slider */}
    <div className="d-flex justify-content-between position-absolute" style={{ width: "80%", left: "50%", transform: "translateX(-50%)", top: "14px" }}>
    {[0, 1, 2, 3].map((dot) => (
        <span 
          key={dot}
          style={{
            marginTop: "2px", // ✅ Aligns dots with the track
            width: "11px",
            height: "10px",
            backgroundColor: "#A9A9A9", // Light gray dots
            borderRadius: "50%",
            display: "inline-block"
          }}
        ></span>
      ))}
    </div>

    {/* Slider Input */}
    <input
      type="range"
      min="0"
      max="3"
      step="1"
      value={priorities[index]}
      onChange={(e) => {
        const updated = [...priorities];
        updated[index] = Number(e.target.value);
        setPriorities(updated);
        setHasChanged(true);
      }}
      className="form-range"
      style={{
        width: "80%", // Adjust width to fit container
        display: "block",
        margin: "0 auto",
        appearance: "none",
        WebkitAppearance: "none",
        // background: "linear-gradient(to right, #E08338 0%, #E08338 " + ((priorities[index] / 3) * 100) + "%, #D3D3D3 " + ((priorities[index] / 3) * 100) + "%, #D3D3D3 100%)",
        background: "transparent",
        position: "relative",
        zIndex: "2",
        marginBottom: "35px",
        pointerEvents: "auto",
      }}
    />

    {/* Priority Label (Follows Slider Thumb) */}
    <div
      className="position-absolute text-center"
      style={{
        left: `calc(${(priorities[index] / 3) * 80}% + 100px)`, // Tracks slider movement
        top: "30px", // ✅ Always keeps it below the slider
        transform: "translateX(-50%)", /* ✅ Ensures label is centered */
        minWidth: "90px", // Prevents too-small labels
        maxWidth: "110px", // Prevents label from stretching too wide
        whiteSpace: "nowrap", // Prevents text from wrapping
        overflow: "hidden", // Ensures it doesn’t extend out of the box
        textOverflow: "ellipsis", // If text is too long, it will be truncated
        transition: "left 0.4s ease-in-out",
      }}
    >
      <span
        className="badge px-3 py-1"
        style={{
          backgroundColor: "#FCE9DA", // Light orange background
          color: "#E08338", // Orange text
          borderRadius: "15px",
        }}
      >
        {["Not a priority", "Low priority", "Medium priority", "High priority"][priorities[index]]}
      </span>
    </div>
  </div>
</div>

          ))}

          <div className="d-flex flex-column align-items-center mt-4 w-100">
            <button 
              onClick={() => setStep(3)} 
              disabled={!hasChanged} 
              className="btn btn-warning text-white w-100 py-2 fw-bold"
              style={{ backgroundColor: "#E08338", color: "white" }}
            >Next
            </button>
            <button
              className="btn btn-link mt-3"
              style={{ textDecoration: "underline", color: "black" }}
              onClick={() => setStep(1)}
            >
              Back
            </button>
          </div>
        </>
      )}

      {/* STEP 3: Text Input */}
      {step === 3 && (
        <>
          <h2 className="fw-bold mt-3">Other priorities</h2>
          <p className="text-muted">
            Are there other priorities not listed in the Schema that you believe are important for us to focus on in the next 10 years?
          </p>
          <textarea
            className="form-control mt-3"
            placeholder="Enter text..."
            value={response}
            onChange={(e) => setResponse(e.target.value)}
            onInput={(e) => {
              e.target.style.height = "auto"; // Reset height
              e.target.style.height = e.target.scrollHeight + "px"; // Expand based on content
            }}
            maxLength={300}
            style={{
              minHeight: "100px",
              maxHeight: "300px", // Optional: Prevent excessive height
              overflowY: "hidden", // Hide scrollbar for a smooth experience
              resize: "none", // Disable manual resizing
            }}
          />
          <div className="text-end text-muted mt-1">{response.length}/300 characters</div>
          <div className="d-flex flex-column align-items-center mt-4 w-100">
            <button
              onClick={handleSubmit}
              disabled={!response.trim()}
              className="btn btn-warning text-white w-100 py-2 fw-bold"
              style={{
                // backgroundColor: response.trim() ? "#E08338" : "rgba(169, 169, 169, 0.5)", // Orange when active, grey when disabled
                // color: response.trim() ? "white" : "black", // White text when active, black when disabled
                // border: "none",
                // transition: "background-color 0.3s ease, color 0.3s ease",
                // cursor: response.trim() ? "pointer" : "not-allowed",
                // boxShadow: response.trim() ? "0px 4px 10px rgba(0, 0, 0, 0.15)" : "none",
                backgroundColor: isSubmitLoading ? "#b3b3b3" : "#E08338", 
                color: "white",
                cursor: isSubmitLoading ? "not-allowed" : "pointer",
                opacity: isSubmitLoading ? 0.7 : 1
              }}
            >
               {isSubmitLoading ? (
                <>
                  <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                  Submitting...
                </>
              ) : (
                "Submit"
              )}
            </button>
            <button
              className="btn btn-link mt-3"
              style={{ textDecoration: "underline", color: "black" }}
              onClick={() => setStep(2)}
            >
              Back
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default MovementThree;
