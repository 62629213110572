export function getTimeslot(
  currentLevel,
  timeslot,
  registrationDatum,
  programme
) {
  const { status } = registrationDatum;
  const { timeslots, startLevel, endLevel } = programme;
  //   console.log(registrationDatum);
  //   console.log(programme);

  // account for old registrations where timeslot (string) is a name instead of id number
  if (registrationDatum?.timeslot.length > 2) {
    return { status: 0, text: registrationDatum.timeslot };
  }

  if (!timeslot?.hasOwnProperty("levels")) {
    return { status: 0, text: timeslot?.name };
  }

  // if (
  //   !(status === 0 && currentLevel >= startLevel && currentLevel <= endLevel)
  // ) {
  //   return { status: 1 }; //next registration
  // }

  if (timeslot?.levels?.includes(currentLevel)) {
    return { status: 0, text: timeslot?.name }; //next registration
  }
  //get next
  return findNextTimeslot(timeslots, timeslot, currentLevel);
}

function findNextTimeslot(timeslots, timeslot, currentLevel) {
  let { id: timeslotId, nextTimeslotId } = timeslot;
  let nextTimeslotIndex = timeslots.findIndex(
    ({ id }) => id === nextTimeslotId
  );
  const visited = [timeslotId];

  for (
    let i = timeslotId;
    nextTimeslotIndex >= 0 && i < timeslots.length;
    i++
  ) {
    if (
      nextTimeslotIndex == null ||
      nextTimeslotIndex < 0 ||
      nextTimeslotIndex >= timeslots.length
    ) {
      return { status: 3 };
    }
    const timeslot = timeslots[nextTimeslotIndex];
    // console.log(visited);
    // console.log(timeslot);

    if (visited.includes(timeslot.id)) {
      return { status: 2 }; //invalid config
    }
    if (timeslot?.levels?.includes(currentLevel)) {
      return { status: 0, text: timeslot?.name };
    }

    visited.push(timeslot.id);
    // nextTimeslotIndex = timeslot?.nextTimeslotId;
    nextTimeslotIndex = timeslots.findIndex(
      ({ id }) => id === timeslot.nextTimeslotId
    );
  }
  return { status: 4 }; //invalid config AND not found
}
