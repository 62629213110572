import React, { useContext } from "react";
import { ArchAssemblyContext } from "./archAssemblyReducer";

const GroupResponseDetail = () => {
  const { assemblyState, assemblyStateDispatch } = useContext(ArchAssemblyContext);
  const { groupSubmissions, responseDetailId } = assemblyState;

  const response = groupSubmissions.find(sub => sub.id === responseDetailId);

  if (!response) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="p-6 max-w-2xl bg-white shadow-lg rounded-lg">
          <h2 className="text-xl font-bold mb-4 text-center">Response Not Found</h2>
          <p className="text-gray-600 text-center">The selected response does not exist.</p>
          <button
            className="w-full mt-4 py-2 bg-orange-600 text-white font-semibold rounded-lg shadow hover:bg-orange-700 transition"
            onClick={() => assemblyStateDispatch({ type: "SET_PAGE", payload: { page: "THEME_RESPONSES" } })}
          >
            Back
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="p-6 max-w-2xl bg-white shadow-lg rounded-lg">
        <h2 className="text-xl font-bold mb-4 text-center">Group {response.submittedByGroup}</h2>
        <div className="max-h-96 overflow-y-auto p-4 bg-gray-100 rounded-lg"
             style={{ wordWrap: "break-word", overflowWrap: "break-word" }}>
          <p className="text-gray-800">{response.response}</p>
        </div>

        {/* Back Button */}
        <div className="d-flex justify-content-center mt-3">
        <button
          className="btn btn-link mt-3"
          style={{ textDecoration: "underline", color: "black" }} 
          onClick={() => assemblyStateDispatch({ type: "SET_PAGE", payload: { page: "THEME_RESPONSES" } })}
        >
          Back
        </button>
        </div>
      </div>
    </div>
  );
};

export default GroupResponseDetail;
