import React, { useState, useEffect, useContext } from "react";
import { ArchAssemblyContext } from "./archAssemblyReducer";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import themes from "./themes.json";
import MoveTwoResponse from "./MoveTwoResponse";

const MovementTwo = () => {
  const { assemblyState, submitMovementTwoReflection, assemblyStateDispatch } = useContext(ArchAssemblyContext);
  const { reflections, delegateProfile } = assemblyState;
  const [themeOptions, setThemeOptions] = useState([]);
  const [selectedTheme, setSelectedTheme] = useState(null);
  const [response, setResponse] = useState("");
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [viewingResponse, setViewingResponse] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false); 

  // Load themes from JSON file
  useEffect(() => {
    if (themes) {
      setThemeOptions(Object.entries(themes));
    }
  }, []);

  // Handle theme selection
  const handleThemeSelection = (key, name) => {
    const alreadySubmitted = reflections.some(
      (resp) => resp.themeNum === key && resp.submittedBy === delegateProfile?.userid
    );

    setSelectedTheme({ key, name });
    setViewingResponse(alreadySubmitted);
    setResponse(""); 
    setHasSubmitted(false); 
  };

  const handleSubmit = async () => {
    if (!selectedTheme || !response.trim() || isSubmitLoading) return; // Prevent empty and multiple submissions
  
    setIsSubmitLoading(true); // Disable button immediately
  
    const submissionObj = {
      themeNum: selectedTheme.key,
      theme: selectedTheme.name,
      response: response.trim(),
      isSubmitted: true,
      submittedBy: delegateProfile?.userid,
      submittedByName: delegateProfile?.name,
      createdAt: new Date().toISOString(), 
    };
  
    try {
      await submitMovementTwoReflection(submissionObj);
      setHasSubmitted(true);
    } catch (error) {
      console.error("Submission failed:", error);
      alert("An error occurred. Please try again.");
    } finally {
      setIsSubmitLoading(false); // Re-enable button after success/failure
    }
  };

  const handleViewResponses = () => {
    setViewingResponse(true);
  };

  const handleBackToThemes = () => {
    setSelectedTheme(null);
  };

  //show confirmation screen 
  if (hasSubmitted && selectedTheme && !viewingResponse) {
    return (
      <div className="text-center">
        <CheckCircleOutlineIcon style={{ fontSize: "40px", color: "green" }} />
        <p>Thank you for your participation.</p>
        <button
          className="btn w-100 my-2"
          style={{ backgroundColor: "#E08338", color: "white" }}
          onClick={handleViewResponses}
        >
          View all responses to this topic  
        </button>
        <button
          className="btn btn-link mt-3"
          style={{ textDecoration: "underline", color: "black" }}
          onClick={handleBackToThemes}
        >
          Submit for another topic
        </button>
      </div>
    );
  }

  if (viewingResponse && selectedTheme) {
    return <MoveTwoResponse themeNum={selectedTheme.key} onBack={handleBackToThemes} />;
  }

  return (
    <div className="container mt-4">
      {!selectedTheme ? (
        <>
          <h4>Personal Reflection</h4>
          <p className="text-muted">
            Please share your input(s) for one or more of these topics.
            <br />
            What is one concrete action that I or my community/organisation can take to begin to realise this pastoral focus?
          </p>
          {themeOptions.map(([key, themeName]) => {
            const alreadySubmitted = reflections.some(
              (resp) => resp.themeNum === key && resp.submittedBy === delegateProfile?.userid
            );
            return (
              <button
                key={key}
                className="btn btn-light border d-flex justify-content-between align-items-center w-100 p-3 mb-2"
                onClick={() => handleThemeSelection(key, themeName)}
                style={{
                  fontSize: "clamp(14px, 4vw, 18px)", // Responsive font size
                  textAlign: "left", // Ensure left alignment
                }}
              >
                {/* Left-aligned text with extra padding */}
                <div className="text-start w-100 ps-2">{themeName}</div>

                {/* Submitted badge or arrow on the right */}
                {alreadySubmitted ? (
                  <span className="badge badge-success">Submitted</span>
                ) : (
                  <span style={{ color: "#E08338", fontSize: "clamp(16px, 5vw, 20px)" }}>&rarr;</span>
                )}
              </button>
            );
          })}


          <div className="d-flex justify-content-center mt-3">
            <button
              className="btn btn-link mt-3"
              style={{ textDecoration: "underline", color: "black" }}              
              onClick={() => assemblyStateDispatch({ type: "SET_PAGE", payload: { page: "HOME" } })}
            >
              Return to Assembly home page
            </button>
          </div>
        </>
      ) : (
        <>
          <h4>Personal Reflection</h4>
          <h5 className="font-weight-bold">{selectedTheme.name}</h5>
          <p className="text-muted">
            What is one concrete action that I or my community/organisation can take to begin to realise this pastoral focus?
          </p>
          <textarea
            className="form-control"
            placeholder="Enter your response"
            value={response}
            maxLength={300}
            onChange={(e) => setResponse(e.target.value)}
            onInput={(e) => {
              e.target.style.height = "auto"; // Reset height first
              e.target.style.height = e.target.scrollHeight + "px"; // Set height to scroll height
            }}
            style={{
              minHeight: "100px",
              maxHeight: "300px", // Optional: Prevent excessive height
              overflowY: "hidden", // Hide vertical scrollbar
              resize: "none", // Disable manual resizing
            }}
          ></textarea>
          <small className="text-muted d-block text-right">{response.length} / 300 characters</small>
          <div className="d-flex flex-column align-items-center text-center">
            <button
              className="btn my-2 w-75"
              style={{
                backgroundColor: "#E08338",
                color: "#fff",
                borderRadius: "10px",
              }}
              disabled={!response.trim() || isSubmitLoading}
              onClick={handleSubmit}
            >
               {isSubmitLoading ? (
                <>
                  <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                  Submitting...
                </>
              ) : (
                "Submit"
              )}
            </button>
            <button
              className="btn btn-link mt-3"
              style={{ textDecoration: "underline", color: "black" }}
              onClick={() => setSelectedTheme(null)}
            >
              Back to Theme Selection
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default MovementTwo;
