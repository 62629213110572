import React from "react";
import loadable from "@loadable/component";
import CenterSpinner from "../../common/centerSpinner";

function AsyncDataVisualisationContainer({ ...rest }) {
  const AsyncLoad = loadable(() => import("./DataVisualisationContainer"));
  console.log("Loading", AsyncDataVisualisationContainer.name);
  return <AsyncLoad {...rest} fallback={<CenterSpinner />} />;
}

export default AsyncDataVisualisationContainer;
