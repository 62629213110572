import React, { Component } from "react";
import { Image } from "react-bootstrap";
import {
  getParishCompleteNameById,
  getParishIdByCode,
  getParishPriestById,
} from "../../services/parish";
import { isValidParish } from "../../services/validation";
import arch from "../../img/priest/arch.jpg";
import blessedsacrament from "../../img/priest/blessedsacrament.jpg";
import cathedral from "../../img/priest/cathedral.jpg";
import ctk from "../../img/priest/ctk.jpg";
import divinemercy from "../../img/priest/divinemercy.jpg";
import holycross from "../../img/priest/holycross.jpg";
import holyfamily from "../../img/priest/holyfamily.jpg";
import holyspirit from "../../img/priest/holyspirit.jpg";
import holytrinity from "../../img/priest/holytrinity.jpg";
import immaculateheartofmary from "../../img/priest/immaculateheartofmary.jpg";
import ladyoflourdes from "../../img/priest/ladyoflourdes.jpg";
import nativityofblessedvirgin from "../../img/priest/nativityofblessedvirgin.jpg";
import perpertualsuccour from "../../img/priest/perpertualsuccour.jpg";
import queenofpeace from "../../img/priest/queenofpeace.jpg";
import starofthesea from "../../img/priest/starofthesea.jpg";
import risenchrist from "../../img/priest/risenchrist.jpg";
import sacredheart from "../../img/priest/sacredheart.jpg";
import novena from "../../img/priest/novena.jpg";
import stanne from "../../img/priest/stanne.jpg";
import stanthony from "../../img/priest/stanthony.jpg";
import stbernadette from "../../img/priest/stbernadette.jpg";
import stfrancisassisi from "../../img/priest/stfrancisassisi.jpg";
import stfrancisxavier from "../../img/priest/stfrancisxavier.jpg";
import stignatius from "../../img/priest/stignatius.jpg";
import stjosephbukittimah from "../../img/priest/stjosephbukittimah.jpg";
import stjosephvictoria from "../../img/priest/stjosephvictoria.jpg";
import stmaryofangels from "../../img/priest/stmaryofangels.jpg";
import stmichael from "../../img/priest/stmichael.jpg";
import ststephen from "../../img/priest/ststephen.jpg";
import stteresa from "../../img/priest/stteresa.jpg";
import stvincentdepaul from "../../img/priest/stvincentdepaul.jpg";
import stspeterandpaul from "../../img/priest/stspeterandpaul.jpg";
import transfiguration from "../../img/priest/transfiguration.jpg";

class ParishPriest extends Component {
  images = [
     { id: 0, temp: true, photo: arch },
      { id: 1, photo: blessedsacrament },
      { id: 2, temp: true, photo: cathedral },
      {id: 3,temp: true,photo: ctk },
      { id: 4, photo: divinemercy },
      { id: 5, photo: holycross },
      { id: 6, photo: holyfamily },
      { id: 7, temp: true, photo: holyspirit },
      { id: 8, temp: true, photo: holytrinity },
      { id: 9, photo: immaculateheartofmary },
      { id: 10, photo: ladyoflourdes },
      { id: 11,temp: true,photo: nativityofblessedvirgin },
      { id: 12,temp: true,photo: perpertualsuccour },
      { id: 13,temp: true, photo: queenofpeace },
      { id: 14,temp: true, photo: starofthesea },
      { id: 15, photo: risenchrist },
      { id: 16, photo: sacredheart },
      { id: 17, temp: true, photo: novena },
      { id: 18, temp: true, photo: stanne },
      { id: 19, temp: true, photo: stanthony },
      { id: 20, photo: stbernadette },
      { id: 21, photo: stfrancisassisi },
      { id: 22, photo: stfrancisxavier },
      { id: 23, photo: stignatius },
      { id: 24, photo: stjosephbukittimah },
      { id: 25,temp: true,photo: stjosephvictoria },
      { id: 26,temp: true, photo: stmaryofangels },
      { id: 27, temp: true, photo: stmichael },
      { id: 28, temp: true, photo: ststephen },
      { id: 29, temp: true, photo: stteresa },
      { id: 30, photo: stvincentdepaul },
      { id: 31, photo: stspeterandpaul },
      {id: 32, temp: true, photo: transfiguration },
      { id: 33, temp: true, photo: arch },
  ];
  constructor(props) {
    super(props);
    const { parish, parishId, parishCode, size } = props;

    this.state = {
      size,
      parish,
      parishId,
      parishCode,
    };
  }
  getImageDisplay = (id) => {
    let imagedisplay;
    let parishId = id;
    if (!isValidParish(parishId)) {
      parishId = 33;
    }
    [imagedisplay] = this.images.filter((img) => img.id === parseInt(parishId));
    return imagedisplay.photo;
  };
  getUniqueParishId = () => {
    const { parish, parishId, parishCode } = this.state;
    let id;
    if (parishId) {
      id = parishId;
    } else if (parishCode) {
      id = getParishIdByCode(parishCode);
    } else if (parish) {
      id = parish._id;
    } else {
      id = 33;
    }
    return id;
  };

  getParishPriestNameOnly = (id) => {
    if (!isValidParish(id)) {
      return null;
    }
    return getParishPriestById(id);
  };

  getParishNameOnly = (id) => {
    let parish = getParishCompleteNameById(id);

    //check if need to split
    if (parish.indexOf("(") !== -1) {
      const first = parish.split("(");
      const second = first[1].split(")");
      parish = (
        <>
          <div className="pt-0 mb-0 text-center">{first[0]}</div>
          <div className="text-center">({second[0]})</div>
        </>
      );
    } else {
      parish = (
        <>
          <div className="my-0 pt-0 text-center">{parish}</div>
          <div className="py-1"></div>
        </>
      );
    }
    return parish;
  };

  getParishPriestName = (id) => {
    let display = (
      <h4 className="pt-1 text-center text-muted">
        <span className="font-italic">my</span>CatholicSG
      </h4>
    );
    if (!isValidParish(id)) {
      return display;
    }
    if (id) {
      display = getParishPriestById(id);
      let parish = getParishCompleteNameById(id);
      //check if need to split
      if (display.indexOf("(") !== -1) {
        let first = display.split("(");
        let second = first[1].split(")");
        display = (
          <React.Fragment>
            <h4 className="pt-1 mb-1 text-center text-muted">{first[0]}</h4>
            <h4 className="text-center text-muted">({second[0]})</h4>
          </React.Fragment>
        );
      } else {
        display = (
          <React.Fragment>
            <h5 className="pt-1 mb-0  pb-0 text-center">{display}</h5>
            <p className="my-0 pt-0 text-center text-muted defaultfontsize">
              {parish}
            </p>
            <div className="py-1"></div>
          </React.Fragment>
        );
      }
    }
    return display;
  };
  render() {
    const id = this.getUniqueParishId();
    if (this.props.size === "medium") {
      return (
        <>
          <Image
            src={this.getImageDisplay(id)}
            width="80px"
            height="80px"
            alt=""
            roundedCircle
          />
          <p className="mb-0 font-weight-bold">
            {this.getParishPriestNameOnly(id)}
          </p>
          <div className="">{this.getParishNameOnly(id)}</div>
        </>
      );
    } else if (this.props.size === "small") {
      return (
        <Image
          src={this.getImageDisplay(id)}
          width="60px"
          height="60px"
          alt=""
          roundedCircle
        />
      );
    }
    return (
      <nav className="navbar navbar-light bg-white flex-column">
        <a className="navbar-brand mx-auto" href="# ">
          <Image
            src={this.getImageDisplay(id)}
            width="150px"
            height="150px"
            alt=""
            roundedCircle
          />
        </a>
        {this.getParishPriestName(id)}
      </nav>
    );
  }
}

export default ParishPriest;
