import React, { useContext, useState } from "react";
import { ArchAssemblyContext } from "./archAssemblyReducer";
import MoveTwoResponseDetail from "./MoveTwoResponseDetail"; // Import detail component
import themes from "./themes.json";

const MoveTwoResponse = ({ themeNum, onBack }) => {
  const { assemblyState, handleEditMoveTwoSubmit } = useContext(ArchAssemblyContext);
  const { reflections, delegateProfile } = assemblyState;

  // State to track if we're viewing details
  const [viewingDetail, setViewingDetail] = useState(null);
  const [visibleCount, setVisibleCount] = useState(5);
  const [editingStates, setEditingStates] = useState({});
  const [updatedResponses, setUpdatedResponses] = useState({});

  // Filter reflections by theme
  const filteredResponses = reflections
    .filter((response) => response.themeNum === themeNum)
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)); // Sorting issue fixed

  // If a specific response is selected, render MoveTwoResponseDetail
  if (viewingDetail) {
    return (
      <MoveTwoResponseDetail
        responseId={viewingDetail}
        themeNum={themeNum}  // ✅ Pass themeNum forward
        onBack={() => setViewingDetail(null)} // ✅ Retain themeNum when going back
      />
    );
  }

  return (
    <div className="p-4 max-w-3xl mx-auto">
      <h2 className="text-xl font-bold mb-4">Responses for {themes[themeNum]}</h2>

      {filteredResponses.length === 0 ? (
        <p className="text-gray-500">No responses yet.</p>
      ) : (
        filteredResponses.slice(0, visibleCount).map((response, index) => (
          <div
            key={index}
            className={`p-4 mb-3 border rounded-lg shadow w-full${
              response.submittedBy === delegateProfile.userid ? "bg-orange-100" : "bg-white"
            }`}
          >
            {/* Display "Your Answer" if the response is from the current user */}
            {response.submittedBy === delegateProfile.userid && (
              <div className="d-flex align-items-center" style={{ justifyContent: "flex-start" }}>
                <span
                  style={{ 
                    backgroundColor: "#FCE3C2", 
                    color: "#E08338", 
                    padding: "4px 8px", 
                    borderRadius: "999px" 
                  }}
                >
                  Your answer
                </span>
              </div>
            )}
            {/* Editable Text or Normal View */}
          {editingStates[response.id] ? (
            <textarea
              className="w-full border rounded p-2 mt-2"
              rows="5"
              style={{ minHeight: "100px", width: "100%", backgroundColor: "white", color: "black" }} 
              value={updatedResponses[response.id] !== undefined ? updatedResponses[response.id] : response.response}
              onChange={(e) => {
                if (e.target.value.length <= 300) { 
                  setUpdatedResponses({ ...updatedResponses, [response.id]: e.target.value });
                }
              }}
            />
          ) : (
            <p
              className="text-gray-800"
              style={{
                wordWrap: "break-word",
                overflowWrap: "break-word",
                wordBreak: "break-word",
                whiteSpace: "pre-wrap",
              }}
            >
              {response.response}
            </p>
          )}
          {/* Edit & Save Buttons */}
          {response.submittedBy === delegateProfile.userid && (
            <div className="d-flex flex-column align-items-end mt-2">
              {/* Character Counter (Only show when editing) */}
              {editingStates[response.id] && (
                <small className="text-muted d-block text-right">
                  {updatedResponses[response.id]?.length || 0} / 300 characters
                </small>
              )}

              {!editingStates[response.id] ? (
                <button
                  className="px-4 py-2 border rounded"
                  style={{
                    backgroundColor: "white",
                    color: "#E08338",
                    border: "2px solid #E08338",
                    fontSize: "14px",
                    cursor: "pointer",
                  }}
                  onClick={() => setEditingStates({ ...editingStates, [response.id]: true })}
                >
                  Edit
                </button>
              ) : (
                <button
                  className="px-4 py-2 rounded"
                  style={{
                    backgroundColor: updatedResponses[response.id]?.trim() ? "#E08338" : "#ccc",
                    color: "white",
                    cursor: updatedResponses[response.id]?.trim() ? "pointer" : "not-allowed",
                  }}
                  disabled={!updatedResponses[response.id]?.trim()} 
                  onClick={() => {
                    handleEditMoveTwoSubmit(response.id, updatedResponses[response.id]); 
                    setEditingStates({ ...editingStates, [response.id]: false }); 
                  }}
                >
                  Save Changes
                </button>
              )}
            </div>
          )}
          </div>
        ))
      )}


      {/* Load More Button */}
      <div className="btn w-100 justify-content-center py-3 my-2">
        {visibleCount < filteredResponses.length && (
          <button
            onClick={() => setVisibleCount((prev) => prev + 3)}
            className="py-3 px-6 text-gray-900 bg-white border border-gray-300 
                      rounded-lg font-medium text-center shadow-md hover:shadow-lg 
                      transition-shadow duration-200"
            style={{
              width: "100%", // Makes it match the parent container
              maxWidth: "768px", // Matches the max width of the response boxes
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.15)", // A subtle shadow for contrast
            }}
          >
            Load more...
          </button>
        )}
      </div>

      {/* ✅ Back Button as an Underlined Text Link */}
      <div className="d-flex justify-content-center mt-3">
        <button
          className="btn btn-link mt-3"
          style={{ textDecoration: "underline", color: "black" }}   
          onClick={onBack}
        >
          View all topics
        </button>
      </div>
    </div>
  );
};

export default MoveTwoResponse;
