import React, { useContext, useState, useEffect, useRef } from "react";
import { ArchAssemblyContext } from "./archAssemblyReducer";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import fire from "../../services/fire";
import themes from "./themes.json";

const MovementOne = () => {
  const { assemblyState, assemblyStateDispatch, submitMovementOneReflection } =
    useContext(ArchAssemblyContext);
  const { delegateProfile } = assemblyState;
  const isFacil = delegateProfile?.isFacil;

  const [reflectionText, setReflectionText] = useState("");
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [groupSubmitted, setGroupSubmitted] = useState(false);

  // useEffect(() => {
  //   console.log("Full Delegate Profile:", delegateProfile);
  // }, [delegateProfile]);

  // call a fetch , via useEffect
  useEffect(() => {
    if (delegateProfile?.groupNum) {
      const groupSubmission = assemblyState.groupSubmissions.find(
        (submission) =>
          submission.submittedByGroup === delegateProfile.groupNum &&
          submission.themeNum === delegateProfile.themeNum
      );
      setGroupSubmitted(!!groupSubmission);
    }
  }, [assemblyState.groupSubmissions, delegateProfile.groupNum]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // submit
  const handleSubmit = () => {
    if (!reflectionText.trim() || isSubmitLoading) return; // Prevent multiple clicks
    setIsSubmitLoading(true); // 🚀 Disable button immediately

    const submissionData = {
      response: reflectionText.trim(),
      themeNum: delegateProfile.themeNum, // Ensure this is valid
      isSubmitted: true,
      submittedByGroup: delegateProfile.groupNum, // Ensure this is valid
      submittedByName: delegateProfile.name,
      submittedBy: delegateProfile.userid,
      createdAt: new Date().toISOString(),
    };

    setIsSubmitLoading(true);
    submitMovementOneReflection(submissionData, () => {
      setIsSubmitLoading(false);
      setGroupSubmitted(true);
    });
  };

  // handlers
  const handleViewResponses = () => {
    assemblyStateDispatch({
      type: "SET_PAGE",
      payload: { page: "GROUP_RESPONSES" },
    });
  };

  const handleBack = () => {
    assemblyStateDispatch({ type: "SET_PAGE", payload: { page: "HOME" } });
  };

  // the h3 themeNum shld eventually return the theme name instead for the final prod
  return (
    <div
      className="p-4 d-flex flex-column"
      style={{ height: "auto", overflowY: "auto" }}
    >
      <p>
        <b>Group Reflection: Group {delegateProfile.groupNum}</b>
      </p>
      <h5>
        {delegateProfile.themeNum} : {themes[delegateProfile.themeNum]}
      </h5>
      <p>
        Provide a summary of your group's discernment, including{" "}
        <b>new perspectives, possibilities for action</b>, and{" "}
        <b>what is becoming clearer</b>.
      </p>

      {isFacil ? (
        groupSubmitted ? (
          <div className="text-center">
            <CheckCircleOutlineIcon
              style={{ fontSize: "40px", color: "green" }}
            />
            <p>Thank you for your participation.</p>
            <button
              className="btn w-100 my-2"
              style={{ backgroundColor: "#E08338", color: "white" }}
              onClick={handleViewResponses}
            >
              View all group responses
            </button>
            <button
              className="btn btn-link mt-3"
              style={{ textDecoration: "underline", color: "black" }}
              onClick={handleBack}
            >
              Return to Assembly home page
            </button>
          </div>
        ) : (
          <>
            <textarea
              className="form-control"
              placeholder="Enter your group reflection..."
              value={reflectionText}
              onChange={(e) => setReflectionText(e.target.value)}
              onInput={(e) => {
                e.target.style.height = "auto"; // Reset height
                e.target.style.height = e.target.scrollHeight + "px"; // Expand based on content
              }}
              style={{
                minHeight: "100px",
                maxHeight: "300px", // Prevent excessive height
                overflowY: "hidden", // Hide scrollbar for smooth UX
                resize: "none", // Disable manual resizing
              }}
            />
            <button
              className="btn w-100 my-3"
              style={{ backgroundColor: "#E08338", color: "white" }}
              onClick={handleSubmit}
              disabled={isSubmitLoading}
            >
              {isSubmitLoading ? (
                <>
                  <span
                    className="spinner-border spinner-border-sm me-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Submitting...
                </>
              ) : (
                "Submit"
              )}
            </button>
            <button
              className="btn w-100"
              style={{ backgroundColor: "#E08338", color: "white" }}
              onClick={handleBack}
            >
              Back
            </button>
          </>
        )
      ) : (
        <>
          <p className="text-muted" style={{ fontSize: "0.9rem" }}>
            You can only see others' responses after your group submits a
            reflection. Once submitted, refresh the page and try again.
          </p>
          {groupSubmitted ? (
            <button
              className="btn w-100 my-2"
              style={{ backgroundColor: "#E08338", color: "white" }}
              onClick={handleViewResponses}
            >
              View all group responses
            </button>
          ) : (
            <button
              className="btn w-100 my-2"
              style={{ backgroundColor: "#D3D3D3", color: "#888" }}
              disabled
            >
              View all group responses (Not Available Yet)
            </button>
          )}
          <button
            className="btn w-100"
            style={{ backgroundColor: "#E08338", color: "white" }}
            onClick={handleBack}
          >
            Back
          </button>
        </>
      )}
    </div>
  );
};

export default MovementOne;
